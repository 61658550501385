<template>
 <div class="login_form">
   <div class="headBox">
      <span style="color:aliceblue;font-size:20px;">
        {{$t('common.message')}}
      </span>
    </div>
  <Main-tab-bar></Main-tab-bar>

 </div>
</template>

<script>

import MainTabBar from '../components/tabbar/MainTabBar'
export default {
  components: {
MainTabBar
  },
}
</script>

<style lang="scss" scoped>

.login_form {
   background: #0b1520;
   height: $full-page;
  position: relative;
  overflow: hidden;
  }


</style>

