<template>
    <div class="game-box">
        <div v-if="!loading" ref="gameList" class="game-list">
            <GameCard v-for="(item,i) in list" :item="item" :width="1" :key="i">
            </GameCard>
        </div>
        <div v-else class="spin">
            <Spin></Spin>
        </div>
    </div>
</template>
<script>
import GameCard from "@/components/Card.vue";

export default {
    name: "",
    components: {GameCard},
    data() {
        return {
            loading: true,
            list: []
        }
    },
    mounted() {
        this.$bus.$on('searchFnEmit', this.searchFn)
    },
    methods: {
        searchFn(val) {
            this.loading = true
            this.list = []
            this.$http.get(this.host + '/game/getGame', {params: {param: val}}).then(response => {
                const resp = response.body;
                if (resp.code == '0000') {
                    resp.data.list.map((item, i) => {
                        // if(type === 5) console.log(item.gameName);
                        // 处理特殊情况，如 The Catfather 需要特殊的图片文件名
                        if (item.gameName.toLowerCase() === 'the catfather') {
                            // 特定文件名的处理
                            item.path = require('@/assets/gameImg/' + 'The Catfather Part II.webp');
                        } else {
                            // 默认情况下根据 gameName 动态加载图片
                            try {
                                // 使用 require 动态加载图片路径，注意 .webp 结尾
                                item.path = require(`@/assets/gameImg/${item.gameName}.webp`);
                            } catch (e) {
                                try {  //第一次匹配失败 转为小写
                                    // console.log('--------------------')
                                    item.path = require(`@/assets/gameImg/${item.gameName.toLowerCase()}.webp`)
                                } catch (err) {
                                    // console.error(`无法加载图片: ${item.gameName}.webp`);
                                    // 如果图片不存在，设置默认图片
                                    item.path = require('@/assets/gameImg/default.png');
                                }
                            }
                        }
                    });
                    this.list = resp.data.list
                    this.loading = false
                } else {
                    this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.mesg
                    });
                    // this.realHeight = '50rem'
                }
            });

        }
    }
}
</script>

<style scoped lang="scss">
.game-box {
    width: 100%;
    height: auto;
    margin: 10px auto;
    overflow-x: scroll;

    .game-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .spin{
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
