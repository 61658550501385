<template>
  <div class="login_form" >

    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span  >
        {{$t('uc.finance.gamedetail')}}
      </span>
      <div style="display: flex;align-items: center;">
        <img src="../assets/logo/ma11.png" width="20" alt="" @click="otherpicke">
     </div>
      <!-- <img src="../assets/logo/date.png" width="20" alt="" @click="popdata"> -->
    </div>
<!--
    <div style="margin-top:100px">
  <Button style="width:40%; height: 40px; background-color: #e5b932; font-size: medium; border: none;  color: #434240; font-weight: 600;"   @click="popdata">{{$t('common.date')}}</Button>
</div> -->

<div class="sports">

  <!-- <Button @click="sportRecord" style="border-radius: 20px;" :class="[isActive == 0?'active':'disActive']" >{{$t('uc.finance.xx131')}}</Button>

  <Button @click="gameRecord" style="border-radius: 20px;" :class="[isActive == 1?'active':'disActive']" >{{$t('uc.finance.xx132')}}</Button>

<Button @click="results" style="border-radius: 20px;" :class="[isActive == 2?'active':'disActive']" >{{$t('uc.finance.xx133')}}</Button> -->

<Tabs :value="currentab" @on-click="handletabs">
  <TabPane :label="$t('uc.finance.xx132')" name="leyou" style="padding-left: 10px;padding-right: 10px;">

<div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"  v-if="showxxy==true">
  <span style="font-size: x-small;color: #4b4b4b; ">暂无记录</span>
</div>

<Scroll :on-reach-bottom="nextPage" :height=realHeight>
  <div dis-hover v-for="(item, index) in datalist1" :key="index"  :class="index%2 == 0? 'active': 'notactive'">



<div style="width: 100%;display: flex;flex-direction: row;">
<div class="xxx1">
<div style="font-size: medium;color: aliceblue">
         {{ item.gamePlatform }}-{{ item.gameType }}
        </div>
<div>
<span style="font-size: x-small;color: #4b4b4b">{{item.name}}</span>

</div>
<div>
    <span style="font-size: x-small;color: #4b4b4b;">{{ item.thirdNo }} </span>
    <img src="../assets/logo/copy.png" v-clipboard:copy="item.thirdNo" v-clipboard:success="onCopy" v-clipboard:error="onError" width="10" height="10" alt="">
  </div>
</div>
<div class="xxx2">
  
  <div>
        <span style="color: #8d8d8d;margin-right: 5px;font-size: x-small;">{{ item.bet }}</span>
        
      </div>
 
</div>
<div class="xxx3">
  <div style="display: flex;flex-direction: row;align-items: baseline;">
    <div v-if="Number(item.payout) - Number(item.bet) < 0" >
        <span style="color: #0db76d;font-size: x-small;">{{(Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
         </div>
      <div v-else >
        <span style="color: #b7240d;font-size: x-small;"> {{(Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
       </div>
    <span style="color: #4b4b4b;font-size: x-small;margin-left: 5px;">{{ item.ccy }} </span>
  </div>
     
       <span style="font-size: x-small;color: #b1bad3;">{{ item.endTime }}</span>

</div>
</div>


<!-- <div style="display: flex; justify-content: flex-end; font-size: small;color: gray;">
  <span>{{item.startTime}}</span>
 </div> -->
</div>
</Scroll>


</TabPane>
        <TabPane :label="$t('uc.finance.xx131')" name="cmd" style="padding-left: 10px;padding-right: 10px;">

          <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"  v-if="showxxy==true">
            <!-- <img src="../assets/logo/norec.png" width="100"  alt=""> -->
            <span style="font-size: x-small;color: #4b4b4b; ">暂无记录</span>
          </div>

          <Scroll :on-reach-bottom="nextPage"  :height=realHeight >
            <div dis-hover v-for="(item, index) in datalist" :key="index" :class="index%2 == 0? 'active': 'notactive'">

<div style="width: 100%;display: flex;flex-direction: column;">
  <!-- <div style="width: 100%;display: flex;flex-direction: column;">
    <div style="display: flex;width: 100%;justify-content: space-between;flex-direction: row;">
      <div>
        <span style="font-size: medium;color: aliceblue;font-weight: 600;">{{ item.optionName }} @ {{ item.odds }}</span>
      </div>
     <div>
      <span style="font-size: medium;color: aliceblue">{{ handOU(item.orderStatus) }}</span>
     </div>
     
      </div>
      
      <div style=" width: 100%; font-size: small;color: #4b4b4b;display: flex;justify-content: flex-start;">
        <span>{{ item.marketName }} {{ gameOddType(item.oddsFormat) }}</span>
      </div>
      <div style=" width: 100%; font-size: x-small;color: #4b4b4b;display: flex;justify-content: flex-start;">
        <span style="font-size: x-small;color: #4b4b4b">{{item.matchTime}}</span>

        </div>
          </div>

          <Divider/>
          <div style="display: flex;flex-direction: column;width: 100%;align-items: flex-start;">
            
                  <span style="color: aliceblue;font-size: medium;">{{  item.matchName }} </span>
                  <span style="color: #4b4b4b;font-size: x-small;">{{ item.tournamentName }}</span>
          
           
          </div>


          <Divider/> -->
          <div style="display: flex;width: 100%;justify-content: space-between;">
            <div style="display: flex; flex-direction: column;">
              <span style="font-size: medium;color: aliceblue;margin-right: 1px;">{{ $t('uc.finance.bet') }}</span>
              <span style="color: #8d8d8d;margin-right: 5px;font-size: x-small;">{{ item.bet }}</span>
            </div>

            <div style="display: flex; flex-direction: column;">
              <span style="color: transparent;">占位符</span>
      <span style="font-size: x-small;color: aliceblue">{{ handOU(item.status) }}</span>
     </div>
            <div  style="display: flex; flex-direction: column;" v-if="Number(item.status) == 5">

              <!-- <span style="font-size: medium;color: aliceblue;">
                {{ $t('uc.finance.xx211') }}
              </span> -->
              <span v-if="handleStatus(item) < 0" style="color: #0db76d;font-size: x-small;"> {{ handleStatus(item) }}</span>
              <span v-else style="color: #b7240d;font-size: x-small;">{{ handleStatus(item) }}</span>
            </div>

            <div  style="display: flex; flex-direction: column;" v-else-if="Number(item.status) == 4">
              
                <span  style="color: transparent;font-size: x-small;">{{ handleStatus(item) }}</span>
              
<span style="font-size: x-small;color: aliceblue;">
  {{ $t('uc.finance.xx211') }}
</span>
<span v-if="Number(item.payout) < 0" style="color: #0db76d;font-size: x-small;"> {{ Number(item.maxWinAmount).toFixed(2) }}</span>
<span v-else style="color: #b7240d;font-size: x-small;">{{ Number(item.maxWinAmount).toFixed(2) }}</span>
</div>


          </div>

          <!-- <Divider/> -->
          <div style="display: flex;width: 100%;justify-content: space-between;">
 <div>
                <span style="font-size: x-small;color: #4b4b4b;"> {{ item.thirdNo }} </span>
                <img src="../assets/logo/copy.png" width="10" height="10" v-clipboard:copy="item.thirdNo" v-clipboard:success="onCopy" v-clipboard:error="onError" alt="">
            </div>

<div>
  <span style="font-size: x-small;color: #b1bad3;">
    {{ item.getTime }}
  </span>

</div>
          </div>
  </div>


</div>
        </Scroll>

        </TabPane>
      


        <!-- <TabPane :label="$t('uc.finance.xx133')" name="bingon">
          <div style="display: flex;flex-direction: column;margin-top: 50%; width: 100%;align-items: center;"  v-show="showxxy">
            <img src="../assets/logo/norec.png" width="100"  alt="">
            <span style="font-size: x-small;color: #4b4b4b; ">暂无记录</span>
          </div>
        </TabPane> -->
    </Tabs>
</div>


<!--
<div class="sports" v-if="isActive == 0">
  <Tabs :value="sportTab"  @on-click="getsubData" border="false" style="width: 100%;">
        <TabPane :label="$t('uc.finance.xx149')" :index='1' name="sp1" style="margin-top: -20px;">
          <Scroll :on-reach-bottom="nextPage" :height="realHeight">
            <Card dis-hover v-for="(item, index) in datalist" :key="index" :class="index%2 == 0? 'active': 'notactive'">
              <div class="head_box" style="width: 100%;">

                <div style="width: 30%;text-align: left;font-size:x-small;">
                  <span>{{ item.thirdPlatform }}/{{ handleSports(item)  }}</span>
                </div>
                <div style="width: 30%;text-align: left;margin-left: 15%;font-size: x-small;">
                  <span>{{  $t('uc.finance.validBet') }} {{ item.bet }}</span>

                </div>

                <div style="width: 30%;text-align:left;margin-left: 10%;font-size: x-small;">
                  <span>{{ handleStatus(item) }}</span>
                  <span v-if="Number(item.payout) < 0" style="color: #0db76d;"> {{ Number(item.payout).toFixed(2) }}</span>
                 <span v-else style="color: #b7240d;">{{ Number(item.payout).toFixed(2) }}</span>

                </div>

          </div>
          <div style="margin-top:5px;">
            <div class="body_box1">

              <span style="font-size: x-small;">{{item.startTime}}</span>
              <span style="font-size: x-small;margin-left: 8%;">{{ $t('uc.finance.xx75') }} : {{ item.thirdNo }} </span>





          </div>

          </div>
            </Card>
        </Scroll>
        </TabPane>
        <TabPane :label="$t('uc.finance.xx150')" :index='2' name="sp2" @click="subSaba"></TabPane>


    </Tabs>


</div> -->

<!-- <div class="gamerecords" v-if="isActive == 1" >
  <Tabs :value="gameTab" border="false" @on-click="getsubData" style="width: 100%;">
    <div style="display: flex;flex-direction: column; width: 100%;z-index: 200;position: fixed;height: 50px;background-color: aliceblue;"  v-if="isGameTouched == true">
      <div style="width:100%;display: flex;justify-content: space-between;padding-left: 10px;padding-right: 10px;">

      </div>


    </div>
        <TabPane :label="$t('uc.finance.xx151')" :index='1' name="g1" @click="subPP" style="margin-top: -20px;">
          <Scroll :on-reach-bottom="nextPage" :height="realHeight">
            <Card dis-hover v-for="(item, index) in datalist" :key="index"  :class="index%2 == 0? 'active': 'notactive'">
              <div class="head_box">
                <span style="font-weight: 600;">{{ gamePlatform(item.gamePlatform, item.gameId) }}</span>
              <span style="font-weight: 600;">{{ gameType(item.gamePlatform,item.gameId) }}</span>



          </div>
          <div style="margin-top:5px;">
            <div class="body_box">
              <div style="display:flex;justify-content: space-between; width: 100%;font-size: small;">

                <div>
                <span>{{ item.ccy }} : </span>
                <span v-if="Number(item.payout) - Number(item.bet) < 0" style="color: #0db76d;">{{(Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                <span v-else style="color: #b7240d;">{{(Number(item.payout) - Number(item.bet)).toFixed(2) }}</span>
                </div>

                <span>{{item.startTime}}</span>
              </div>

          </div>

          </div>
            </Card>
        </Scroll>
        </TabPane>

    </Tabs>

</div> -->


<Drawer :title="$t('uc.finance.xx3')" placement="bottom"  v-model="value8" height="30" >

<RadioGroup v-model="rechargestatus"  v-if="currentName == 'CMD体育'">
      <Radio label="0" >{{ $t('common.exchangeAll') }}</Radio>
      <Radio label="1" >{{$t('uc.finance.xx137')  }}</Radio>
      <Radio label="2" >{{$t('uc.finance.xx138')  }}</Radio>
      <Radio label="3" >{{$t('uc.finance.xx139')  }}</Radio>
      <Radio label="4" >{{$t('uc.finance.xx140')  }}</Radio>

      <Radio label="5" >{{ $t('uc.finance.xx193') }}</Radio>
      <!-- <Radio label="6" >{{ $t('uc.finance.xx194') }}</Radio> -->
      <Radio label="7" >{{ $t('uc.finance.xx195') }}</Radio>
      <!-- <Radio label="8" >{{ $t('uc.finance.xx196') }}</Radio> -->
      <Radio label="9" >{{ $t('uc.finance.xx197') }}</Radio>
      <Radio label="10" >{{ $t('uc.finance.xx198') }}</Radio>
  </RadioGroup>

  <RadioGroup v-model="withdrawstatus" v-else-if="currentName == 'saba体育'">

      <Radio label="0" >{{$t('common.exchangeAll')  }}</Radio>
      <Radio label="1" >{{$t('uc.finance.xx137')  }}</Radio>
      <Radio label="2" >{{$t('uc.finance.xx138')  }}</Radio>
      <Radio label="3" >{{$t('uc.finance.xx139')  }}</Radio>
      <Radio label="4" >{{$t('uc.finance.xx140')  }}</Radio>
  </RadioGroup>

  <RadioGroup v-model="commonstatus" v-else>
      <Radio label="0" >{{$t('common.exchangeAll')  }}</Radio>
      <Radio label="1" >{{$t('uc.finance.xx137')  }}</Radio>
      <Radio label="2" >{{$t('uc.finance.xx138')  }}</Radio>
      <Radio label="3" >{{$t('uc.finance.xx139')  }}</Radio>
      <Radio label="4" >{{$t('uc.finance.xx140')  }}</Radio>
  </RadioGroup>

  <Button type="primary" long style="margin-top: 20px;" @click="closebtm">{{ $t('common.ok') }}</Button>

  </Drawer>

<calendar :show.sync="calendarShow" :defaultDate="defaultDate" mode="during" @change="onChange" style="color: #0073ee;"/>

  </div>
</template>

<script>

// import TodoList from '@/components/TodoList'
import dayjs from 'dayjs';
import {checkIsJSON} from '@/config/index'
// import JDB from '../../public/JDB.json'
// import PG from '../../public/PG.json'
import PP from '../../public/PP.json'
// import evo from '../../public/evoL.json'
// import PPl from '../../public/PPl.json'
import {formatTime} from "@/util/tools";

// import inobounce from 'inobounce'

export default {

 data() {
return{
datalist:[],
datalist1: [],
startDate: '',
      endDate : '',
      defaultDate:[ dayjs(), dayjs().add(-7, 'day') ],
      totalregist:'',
      totolwin: 0,
      totalbet: 0,
      totalValidBet: 0,
      calendarShow:false,
      currentPage: 1,
      totalPage: 0,
      isActive: 0,
      iSportTouched: false,
      isGameTouched: false,
      iSportSubActive: 0,
      isGameSubActive: 2,
      realHeight: 0,
      isReachBottom: false,
      sportTab:'sp1',
      gameTab:'g1',
      isSport:  false,
      windowHeight: 0,
      currentName: '',
      value8:false,
      rechargestatus: '',
      withdrawstatus: '',
      commonstatus: '',
      currentab: '',
      showxxy: ''
}
 },

 mounted(){

  this.windowHeight = document.documentElement.clientHeight;
  console.log('yyyyyy '+this.windowHeight);

  this.realHeight = Number(this.windowHeight) - 100
  console.log('xcxcxx '+this.realHeight);
    // if (browser().ios) {
      document.getElementById('outbody').style.overflow = 'hidden'
    // }
  },

created() {



    // if (browser().ios) {
    //   document.getElementById('outbody').style.overflow = 'hidden'
    // }
  // this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  // this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
  // this.currentPage = 1
    // this.initGtCaptcha();
    // this.getPokeGameList()

    this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
  this.currentPage = 1
  this.currentName = 'PP'
  this.currentab = 'leyou'
  this.initGtCaptcha('PP')
  this.iSportSubActive = 0



  },

  beforeDestroy() {
    // inobounce.disable()
    document.getElementById('outbody').style.overflow = ''
  },

methods: {

  closebtm(){
    this.value8 = false
    this.currentPage = 1
    this.isReachBottom = false
    if (this.currentName == 'CMD体育') {
      this.initGtCaptcha('CMD体育')
    }
  },

  handletabs(data){
    this.currentab = data
    if (data == 'cmd') {
      this.getsubData('sp1')
    }else if (data == 'leyou') {
      this.getsubData('g1')
    }
  },

  otherpicke(){
        this.value8 = true
    },
  selfchoose(){
    this.isGameSubActive = 4
    this.popdata()
  },

  gotoDetail(item){
    // window.location.href = item.betDetail
          // window.open(item.betDetail,"self")

    this.getHomeName(item)



  },

  onCopy(e) {
    console.log(''+e )
      this.$Message.success(

        this.$t("uc.finance.copysuccess")
      );
    },
    onError() {
      this.$Message.error(this.$t("uc.finance.copyfailed"));
    },

gameOddType(item){
  let xx=''
    switch (item) {
      case 1:
        xx = this.$t("uc.finance.xx207")
        break;
      case 2:
      xx = this.$t("uc.finance.xx208")
        break;
      case 3:
      xx = this.$t("uc.finance.xx209")
        break;
      case 4:
      xx = this.$t("uc.finance.xx210")
        break;
      default:
        break;
    }

    return xx
},

// handlePPL(game){
//   let xx = ''


//   if (PPl[game]) {
//             xx += '/'+PPl[game].GameName_cn

//           }
//   return xx
// },
// handleEVO(game){
// let xx = ''



// if (evo[game]) {
//             xx += '/'+evo[game].GameName_cn

//           }
// return xx
// },



handleStatus(item){
  let xx = ''
xx = Number(item.payout) - Number(item.bet)

  return xx
},

// xx212:'已创建',
//               xx213:'确认中',
//               xx214: '已拒绝',
//               xx215: '已取消',
//               xx216: '已确认',
//               xx217: '已结算'

handOU(ou){
  let xxy = ''
switch (Number(ou)) {
  case 0:
    xxy = this.$t('uc.finance.xx212')
    break;
    case 1:
    xxy = this.$t('uc.finance.xx213')
    break;
  case 2:
  xxy = this.$t('uc.finance.xx214')
    break;
    case 3:
    xxy = this.$t('uc.finance.xx215')
    break;
    case 4:
    xxy = this.$t('uc.finance.xx216')
    break;
    case 5:
    xxy = this.$t('uc.finance.xx217')
    break;
  default:
    break;
}
return xxy
},



  subPP(){
    this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
  this.currentPage = 1
  this.initGtCaptcha('PP')
  this.isGameSubActive = 0
  },


  subAll(){
    this.iSportSubActive = 0
  },
  subSingle(){
    this.iSportSubActive = 1
  },
  subMore(){
    this.iSportSubActive = 2
  },
  subChamp(){
    this.iSportSubActive = 3
  },



  touchSportBut(){
    this.iSportTouched = !this.iSportTouched
  },

  touchGameBut(){
    this.isGameTouched = !this.isGameTouched
  },
  sportRecord(){
    this.isActive = 0
    this.iSportTouched =false
    this.isGameTouched = false
    this.sportTab = 'sp1'
    this.currentName = 'CMD体育'
    this.rechargestatus = ''
    this.withdrawstatus = ''
    this.commonstatus = ''
    this.subFB()
  },
  gameRecord(){
    this.isActive = 1
    this.iSportTouched = false
    this.isGameTouched = false
    this.rechargestatus = ''
    this.withdrawstatus = ''
    this.commonstatus = ''
    this.gameTab = 'g1'
    this.currentName = '乐游'

    this.subPP()
  },
  results(){
    this.isActive = 2
    this.iSportTouched =false
    this.isGameTouched = false
  },

  nextPage(){

    if (this.isReachBottom == true) {
      return
    }
    let that = this
  let params = {}
     params.beginDateStr = this.startDate
     params.endDateStr = this.endDate
     this.currentPage += 1
     params.pageNum = this.currentPage
     params.pageSize = 20
     params.thirdPlatform = this.currentName

     this.$http.post(this.host + this.api.uc.mdboGameRecordList, params).then(response => {
       var resp = response.body;

                    if (resp.code == "0000") {
                      if(this.currentName == 'FB') {
                        // that.datalist = resp.data.list
                        resp.data.list.forEach(element => {
                          if (element.betDetail) {
                            let xxyy = JSON.parse(element.betDetail).betList[0]
                            xxyy.stakeAmount = JSON.parse(element.betDetail).stakeAmount
                            xxyy.maxWinAmount = JSON.parse(element.betDetail).maxWinAmount
                            xxyy.orderStatus = JSON.parse(element.betDetail).orderStatus
                            xxyy.matchTime = formatTime(xxyy.matchTime)
                            xxyy.calendar1 = element.startTime
                            that.datalist.push(xxyy)
                          }
                          
                        });
                        console.log('sdfsdfd');
                        
                        if (that.datalist.length <= 0) {
                          that.showxxy = true
                        }else
                          that.showxxy = ''
    }else if (this.currentName == 'PP') {
      
      that.datalist1 = resp.data.list.map(item => ({
          ...item,
          name: PP[item.gameId].gameName,
          // value: item.gameID,
          gameType: this.ParseType(PP[item.gameId].type),
        }))
      if (that.datalist1.length <= 0) {
                          that.showxxy = true
                        }else
                          that.showxxy = ''
    }



    that.totalbet = resp.data.totalBet
    that.totolwin = resp.data.totalWin
    that.totalValidBet = resp.data.totalValidBet
    that.totalPage = Number(resp.data.total)
                    }else
                        this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.message
                      });
      });
  },


  goback() {
      //获取个人安全信息
      document.getElementById('outbody').style.overflow = ''
      this.$router.back(-1)

    },
    subFB(){
    this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
  this.currentPage = 1
  this.initGtCaptcha('FB')
  this.iSportSubActive = 0
  },
     getsubData(name){

    switch (name) {
      case 'sp1':

        this.rechargestatus = ''
        this.withdrawstatus = ''
        this.commonstatus = ''
        this.currentName = 'FB'
        this.subFB()
        break;
      case 'sp2':
      this.rechargestatus = ''
        this.withdrawstatus = ''
        this.commonstatus = ''
        this.currentName = 'saba体育'
        this.subSaba()
        break;
      case 'g1':
      this.rechargestatus = ''
        this.withdrawstatus = ''
        this.commonstatus = ''
        this.currentName = 'PP'
        this.subPP()

        break;
      default:
        break;
    }
  },
    onChange(date){
if (date[0] != null && date[1] != null) {
  this.startDate = date[0].format('YYYY-MM-DD')

  this.endDate = date[1].format('YYYY-MM-DD')


this.initGtCaptcha()
}

    },

 initGtCaptcha(platform) {
  let that = this
  let params = {}

     params.pageNum = 1
     this.currentPage = 1
     params.pageSize = 20
     params.thirdPlatform = platform
     this.isReachBottom = false
     if(platform == 'FB') {
      this.datalist = []

     }else if (platform == 'PP') {
      this.datalist1 = []
      if (this.commonstatus == '1'){
        this.endDate= dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().add(-1, 'day').format('YYYY-MM-DD')
    }
    else if (this.commonstatus == '2'){
      this.endDate = dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().format('YYYY-MM-DD')

    }else if (this.commonstatus == '3'){
      this.endDate = dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().add(-7, 'day').format('YYYY-MM-DD')

    }else {
      this.endDate = dayjs().format('YYYY-MM-DD')
      this.startDate  = dayjs().add(-30, 'day').format('YYYY-MM-DD')

    }

    params.beginDateStr = this.startDate
    params.endDateStr = this.endDate
     }

  
       this.$http.post(this.host + this.api.uc.mdboGameRecordList, params).then(response => {
       var resp = response.body;

                    if (resp.code == "0000") {
                      if(platform == 'FB') {
                        // that.datalist = resp.data.list
                        resp.data.list.forEach(element => {
                         
                          if (element.betDetail) {
                            // let xxyy = JSON.parse(element.betDetail).betList[0]
                            // xxyy.stakeAmount = JSON.parse(element.betDetail).stakeAmount
                            // xxyy.maxWinAmount = JSON.parse(element.betDetail).maxWinAmount
                            // xxyy.orderStatus = JSON.parse(element.betDetail).orderStatus
                            // xxyy.matchTime = formatTime(xxyy.matchTime)
                            // xxyy.calendar1 = element.startTime
                            // that.datalist.push(element)
                            element.maxWinAmount =  JSON.parse(element.betDetail).maxWinAmount
                          }
                          that.datalist.push(element)
                        });
                        console.log('sdfsdfd');
                        
                        if (that.datalist.length <= 0) {
                          that.showxxy = true
                        }else
                          that.showxxy = ''
    }else if (platform == 'PP') {
      
      that.datalist1 = resp.data.list.map(item => ({
          ...item,
          name: PP[item.gameId].gameName,
          // value: item.gameID,
          gameType: this.ParseType(PP[item.gameId].type),
        }))
      if (that.datalist1.length <= 0) {
                          that.showxxy = true
                        }else
                          that.showxxy = ''
    }



    that.totalbet = resp.data.totalBet
    that.totolwin = resp.data.totalWin
    that.totalValidBet = resp.data.totalValidBet
    that.totalPage = Number(resp.data.total)
                    }else
                    that.$Notice.error({
                        title: that.$t("common.tip"),
                        desc: resp.message
                      });
      });
    },

    popdata(){
        this.calendarShow = !this.calendarShow
    },

    ParseType(type){
let ss = ''
switch (type) {
  case 'Video Slots':
    ss = this.$t("uc.finance.game85")
    break;
    case 'Live games':
    ss = this.$t("uc.finance.game81")
    break;
  default:
    break;
}

return ss
    },


    handledetail(str, ele1){
      let arr1 = str.split("}-");
      arr1.forEach(element => {
        if (checkIsJSON(element+'}')) {
          let js1 = JSON.parse(element+'}')
          Object.getOwnPropertyNames(js1).forEach(function(key){
    ele1[key] = js1[key]
});

        }else{
          let arr2 = element.split('-')

         let aar21 = arr2[0].split(':')
         ele1.betNo = aar21[1]
          let aar22 = arr2[1].split(':')
          ele1.playID = aar22[1]
          let aar23 = arr2[2].split(':')
          ele1.table = aar23[1]
        }

      });
      console.log("xxx" + ele1);
    },

    handleAGdetail(str, ele1){
      let arr1 = str.split("=");
      arr1 = arr1[1]
      arr1 = arr1.split('-')
      for (let index = 0; index < arr1.length; index++) {
        const element = arr1[index];
        if (index == 0) {
          let arr2 = element.split(',')
          ele1.Playerbillno = arr2[0]
          ele1.sport = arr2[1]
        }else if (index == 1) {
          ele1.Extbillno = element
        }
      }
    },
    // "BJ Baccarat-25,20-Settled."

    handleGBdetail(str, ele1){
        console.log(str, ele1);
    },

    handleIBdetail(str, ele1){
      let arr1 = str.split("-");
      // MatchId -LeagueName                    -HomeIDName  -AwayIDName    -HomeScore-AwayScore-
      for (let index = 0; index < arr1.length; index++) {
        const element = arr1[index];
        if (index == 0) {
          ele1.MatchId = element
        }else if (index == 1) {
          ele1.LeagueName = element
        }else if (index == 2) {
          ele1.HomeIDName = element
        }else if (index == 3) {
          ele1.AwayIDName = element
        }else if (index == arr1.length - 3) {
          ele1.HomeScore = element
        }else if (index == arr1.length - 2) {
          ele1.AwayScore = element
        }
      }
    },

    gamePlatform(platform, gid){

      let xx = ''
      switch (platform) {
        case '1085':
          xx = 'JDB'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '电子'
}
          break;

          case '1046':
            xx = 'IBC'

            if (this.$i18n.locale == 'zh-CN') {
          xx += '体育'
}
            break
            case '1002':
          xx = 'Evo'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '真人'
}
            break
          case '1003':
          xx = 'All Bet'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '真人'
}
            break
            case '1020':
          xx = 'WM'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '/真人'
}
            break
            case '1109':
          xx = 'Netent'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '电子'
}
            break
          case '1006':
          xx = 'PP'
            if (this.$i18n.locale == 'zh-CN' ) {
              // if ( !PP[gid]) {
              //   xx += '/真人'
              // }else
              // xx += '电子'

}
            break

            case '1007':
          xx = 'PG'
            if (this.$i18n.locale == 'zh-CN') {
          xx += '电子'
}
            break

            case '1001':
              xx = 'AG/真人'
              break

        default:
          break;
      }
      return xx
    },



handleWM(xxxu){
  let xx = ''
  switch (xxxu) {
    case 'Baccarat':
      xx = '/百家乐'
      break;
    case 'Dragon&Tiger':
      xx = '/龙虎'
      break;
    case 'NiuNiu':
      xx = '/牛牛'
      break;
      case 'Sedie':
      xx = '/色碟'
      break;
      case 'fish-prawn-crab':
      xx = '/鱼虾蟹'
      break;
      case 'Fantan':
      xx = '/番摊'
      break;
      case 'AndarBahar':
      xx = '/安達巴'
      break;
      case 'Sicbo':
      xx = '/骰宝'
      break;
    default:
      break;
  }

  return xx
},


handleAg(xxx){
  let xx = ''
  switch (xxx) {
    case 'BAC':
      xx = '/百家乐'
      break;
    case 'ROU':
      xx = '/轮盘'
      break;
      case 'ZJH':
      xx = '/扎金花'
      break;
      case 'BJ':
      xx = '/21点'
      break;
      case 'NN':
      xx = '/牛牛'
      break;
      case 'SG':
      xx = '/三公'
      break;
      case 'DT':
      xx = '/龙虎'
      break;
      case 'SHB':
      xx = '/筛宝'
      break;
    default:
      break;
  }

  return xx
},

billType(status){
let xyz = ''
switch (status) {
        case 100:
          xyz = this.$t('uc.finance.xx71')
          break;
          case 101:
          xyz = this.$t('uc.finance.xx70')
          break;
          case 102:
          xyz = this.$t('uc.finance.xx72')
          break;
        default:
          break;
      }
return xyz
    },
    getPokeGameList(){
let param = {};



     param["gameType"] = 2;
     param["productID"] = 1020;
     param['languageCode'] = 3
     param['operatorCode'] = "E503"
     param['platform'] = 0


       this.$http
       .post(this.host + this.api.uc.mdboGameList, param)
       .then(response => {
         let resp = response.body

         if (resp.ErrorCode == 0) {
            let temp = JSON.parse(resp.data)


console.log(temp);
          } else {
            // self.$Message.error(resp.mesg);
            this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc:resp.mesg
                  });
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
      },
},





}
</script>

<style lang="scss" scoped>


.login_form{
    background-color: $color-background;
    height: $full-page;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  color: $color-text-tag;
  z-index: 200;
   width: 100%;
   background-color: $color-background;
}

.head_box{
      display: flex;
      align-items: center;
      width: 100%;
      color: #fff;
      font-size: small;
      height: auto;
      // padding-top: 10px;
    }


    .body_box{
       display: flex;
      color: #fff;
      font-size: 15px;
      width: 100%;
justify-content: space-between;
align-items: center;
      // margin-left: 5%;
      // margin-top: 20px;
      height: auto;

    }

    .body_box1{
       display: flex;
      color: #fff;
      font-size: 15px;
      width: 100%;
align-items: center;

      height: auto;

    }

    /deep/ .ivu-card-body{
  padding:  10px 10px;

}

.head2{
  color:#898989 ;
  display: flex;
  justify-content: space-around;
  margin-top:60px;
  width: 100%;

}
.bodyhead{
  color:#898989 ;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

/deep/ .ivu-card-bordered{
  border: none;
}



/deep/ .ivu-btn{
  border: none
}

.head22{
display: flex;
flex-direction: column;
font-size: medium;
}

.sports{

  margin-top:45px;
}
.gamerecords{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: baseline;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
 /deep/ .ivu-tabs-bar{
  border-bottom: 0px solid #dcdee2;
  margin-bottom: 10px;
  background-color: #1B2C38;
}

.active{
  height: 80px;
  padding: 8px 10px;
  background-color:#1A2B37;
  border-radius:5px;
}

.notactive{
  height: 80px;
  padding: 8px 10px;
  background-color:transparent;
  border-radius:5px;
}

.active1{
  height: 220px;
  padding: 8px 10px;
  background-color:#1A2B37;
  border-radius:5px;
}

.notactive1{
  height: 220px;
  padding: 8px 10px;
  background-color:transparent;
  border-radius:5px;
}

/deep/ .ivu-tabs-nav-container{
  font-size: 12px;
}

/deep/ .ivu-tabs-nav  .ivu-tabs-tab-active{
  color: #d2b965;
}


/deep/ .ivu-tabs-tab{
  color: #fff;
  padding: 12px 16px
}



/deep/ .ivu-tabs-ink-bar{
  background-color: #d2b965;
}

/deep/.ivu-divider-horizontal{
  margin: 5px 0;
}

/deep/ .ivu-divider{
  background-color: #333b4165;
}

.xxx1{
display: flex;
flex-direction: column;
align-items: flex-start;
width: 40%;
}

.xxx2{
  display: flex;
flex-direction: column;
align-items: flex-start;
margin-left: 1px;
margin-top: 7%;

width: 20%;
}
.xxx3{
  display: flex;
align-items: flex-start;
margin-top: 7%;
flex-direction: column;
width: 40%;
}
</style>



