<template>
  <div  class="withdraw">
    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">

      <span  style="font-size:20px;margin-left: 10%;">
        {{$t('uc.finance.charge')}}
      </span>

      <div style="display: flex;align-items: center;">
      {{ $t('common.currencyType') }}
      <img :src='currenyImg' alt="" width="25" @click="popUP" style="margin-left: 10px;">
     </div>
    </div>

    <div class="body">
<div v-if="vertical == 'USD'">
  <!-- <RadioGroup v-model="usdRadio" type="button" @on-change="switchCurrency">
        <Radio label="USDT" border></Radio>
        <Radio label="HUIONE" border></Radio>
        <Radio label="CAMPAY" border></Radio>
  </RadioGroup> -->


  <Tabs value="name1" @on-click="choose1">
        <TabPane label="USDT" name="name1">
          <div class="bill_box">

            <section style="margin-top:20px;" v-if=" totalRadio == 'USDT'">

<div >
    <span style="font-size: large;color: #fff;">{{$t('uc.finance.personalassets')}}:  TRC20</span>
</div>

<vue-qr :logo-src="logoSrc"
      :size="191"
      :margin="10"
      :auto-color="true"
      :dot-scale="1"
      :text="panel1.acctNo"
      colorDark="black"
      colorLight="white"
      style="background-color:azure;margin-top: 20px;margin-bottom: 20px;"
  />

<div class="uclass">
  <span style="color: #ff5722;font-size: large;">1USDT=1USD</span>
  <br>
  <span style="color: #fff;">{{$t('uc.finance.swapassets')}}:{{panel1.acctNo}}</span>


  <Button style="color: #fff; font-weight: bold; border-radius: 10px;  background-color: #4b505c; border: none;" v-clipboard:copy="panel1.acctNo" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>

</div>



  <br>

  <div style="width:100%;margin-bottom: 20px;align-items: start;display: flex;flex-direction: column;padding: 20px;">
    <span style="font-size: large;color: #ff5722;">{{ $t('common.tip') }}:</span>

    <span style="color:#fff;margin-top: 5px;">{{$t('uc.finance.notice11')}}</span>
  </div>


</section>

        </div>

        </TabPane>
        <TabPane label="HUIONE" name="name2">
          <section style="margin-top:10px;">


<div class="uclass1">
  <div style="margin-top:10px;display:flex;justify-content:space-between;width:100%;">
    <div style="color:#fff">
      <span >{{$t('uc.forget.addresstip311')}}:</span>
  {{huioneinfo.walletName}}
    </div>


<div>
<Button style="font-size: 14px;color: #fff; width:80px; height: 25px; border-radius:10px;  background-color: #898989; border: none;" v-clipboard:copy="huioneinfo.walletName" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>

</div>
  </div>

  <div style="width:100%;display:flex;justify-content:space-between; margin-top:10px">
    <div>
      <span style="color:#fff">{{$t('uc.finance.swapassets1')}}:</span>

      {{huioneinfo.walletAddress}}
    </div>

    <div>
      <Button style="font-size: 14px;color: #fff; width:80px; height: 25px; border-radius:10px; background-color: #898989; border: none;" v-clipboard:copy="huioneinfo.walletAddress" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>

    </div>

  </div>



            </div>

<div style="width: 80%;margin-top:40px">

  <Input v-model="huioneName" type="text" size="large" style="color: #898989;border-radius: 10px; border: none; border-inline-width: 1px; width:100%; margin-left: 12%; " clearable   :placeholder="$t('uc.forget.addresstip31')" />

</div>

<div style="width: 80%;margin-top:20px">

  <Input v-model="huionephone" type="text" size="large" style="color: #898989;border-radius: 10px; border: none; border-inline-width: 1px; width:100%;  margin-left: 12%; " clearable   :placeholder="$t('uc.forget.addresstip41')" />

</div>

<div style="width: 80%;margin-top:20px">


<Input v-model="huioneamount" type="text" size="large"  @on-change="onKeyYHKNameInput" style="color: #898989;border-radius: 10px; border: none; border-inline-width: 1px; width:100%;  margin-left: 12%; " clearable   :placeholder="$t('uc.finance.notice12345')" />

</div>

<img id="frontCardImg" v-if="frontCardImg != '' " style="width: 180px;height: 120px;margin-top: 20px;" :src="frontCardImg">
  <div v-else class="acc_sc">
      <Upload ref="upload1" :before-upload="beforeUpload" :on-success="frontHandleSuccess" :headers="uploadHeaders" :action="uploadUrl">
          <Button style="border: none;background:transparent;font-size: large;">{{$t('uc.forget.upload')}}</Button>
      </Upload>
  </div>

<div style="margin-top:40px; margin-bottom: 20px; padding-bottom: 20px;">
<Button style="width:40%; height: 35px; background-color: #ff5722; font-size: medium; border: none;  color: #fff; border-radius:10px"   @click="huioneSubmit">{{$t('uc.forget.save')}}</Button>
</div>

<div style="width:100%;margin-bottom: 20px;align-items: start;display: flex;flex-direction: column;padding: 20px;">
                <span style="font-size: large;color: #ff5722;">{{ $t('common.tip') }}:</span>

                <span style="color:#fff;margin-top: 5px;">{{$t('uc.finance.notice11')}}</span>
              </div>
</section>
        </TabPane>
        <TabPane label="CAMPAY" name="name3">
          <section style="margin-top:0px;">

<!-- <RadioGroup v-model="bankCode" style="margin-top: 10px;  color: #8994a3;" @on-change="switchBank">
<Radio v-for="item in bankList" :label="item.bankName" :key="item.bankId" style='margin: 10px;'>
    <span>{{ item.bankName }}</span>
</Radio>

</RadioGroup> -->
<!-- <div style="display: flex;padding: 10px;flex-direction: column;align-items: flex-start;">
<span style="font-size: medium;color: #fff;">{{$t('uc.finance.xx189')}}:</span>
<back-list @select="selectItem" :list="bankList"></back-list>
</div> -->

<Select v-model="backCode" style="width:80%; margin-top: 10px;" :label-in-value="true" @on-change="selectItem1">
<img :src="currentbankImag" width="20" height="20" alt="" slot="prefix" style="margin-right: 10px;">
<Option v-for="item in bankList" :value="item.bankCode" :key="item.value" style="display: flex;align-items: center;" >
<img :src="item.ImageUrl" width="20" height="20" alt="" style="margin-right: 10px;">
{{ item.bankName }}
</Option>
</Select>


<div style="margin-top: 50px;">

<Input v-model="huioneName" type="text" size="large" style="width: 80%;border-radius: 20px;border: none;"  clearable   :placeholder="$t('uc.finance.xx121')" />

</div>

<div style="margin-top: 10px;">

<Input v-model="campayamount" type="text" size="large" @on-change="onKeyYHKNameInput1" style="width: 80%;border-radius: 20px;border: none;"  clearable   :placeholder="$t('uc.finance.notice12345')" />

</div>

<div style="margin-top:40px; margin-bottom: 20px; padding-bottom: 20px;">
<Button style="width:40%; height: 40px; background-color: #ff5722; font-size: medium; border: none;  color: #fff; font-weight: 600;"   @click="campaySubmit">{{$t('uc.forget.save')}}</Button>
</div>

<!-- <img src="../assets/logo/bankcard_sample.png" width="90%" alt=""> -->
</section>
        </TabPane>
    </Tabs>


      <!-- <Button style="background-color: #ff5722;border-radius: 15px;color: aliceblue;border: none;" v-if="usdtactive == true" @click="usdtclick">USDT</Button>
      <Button  style="border-radius: 15px;color: aliceblue;background-color: #4b505c;border: none;" v-else @click="usdtclick">USDT</Button>
      <Button style="background-color: #ff5722;border-radius: 15px;color: aliceblue;border: none;" v-if="huioneactive == true" @click="huioneclick">HUIONE</Button>
      <Button  style="border-radius: 15px;color: aliceblue;background-color: #4b505c;border: none;" v-else @click="huioneclick">HUIONE</Button>
      <Button style="background-color: #ff5722;border-radius: 15px;color: aliceblue;border: none;" v-if="campayactive == true" @click="campayclick">CAMPAY</Button>
      <Button  style="border-radius: 15px;color: aliceblue;background-color: #4b505c;border: none;" v-else @click="campayclick">CAMPAY</Button> -->

</div>
<div v-else-if="vertical == 'USDT'">
  <!-- <RadioGroup type="button" v-model="usdtRadio">
        <Radio label="USDT" border></Radio>
  </RadioGroup> -->
  <!-- <div style="background-color: #293042;height: 60px;padding-top: 15px;">
    <div style="display: flex;width: 70%;margin-left: 20px;">
      <Button style="background-color: #ff5722;border-radius: 15px;color: aliceblue;border: none;" v-if="usdtactive == true" @click="usdtclick">USDT</Button>
      <Button  style="border-radius: 15px;color: aliceblue;background-color: #4b505c;border: none;" v-else @click="usdtclick">USDT</Button>
    </div>
  </div> -->

  <Tabs value="name5">
        <TabPane label="USDT" name="name5">
          <div class="bill_box">

         <section style="margin-top:20px;" v-if=" totalRadio == 'USDT'">

<div >
 <span style="font-size: large;color: #fff;">{{$t('uc.finance.personalassets')}}:  TRC20</span>
</div>

<vue-qr :logo-src="logoSrc"
   :size="191"
   :margin="10"
   :auto-color="true"
   :dot-scale="1"
   :text="panel1.acctNo"
   colorDark="black"
   colorLight="white"
   style="background-color:azure;margin-top: 20px;margin-bottom: 20px;"
/>

<div class="uclass">
<span style="color: #ff5722;font-size: large;">1USDT=1USD</span>
<br>
<span style="color: #fff;">{{$t('uc.finance.swapassets')}}:{{panel1.acctNo}}</span>


<Button style="color: #fff; font-weight: bold; border-radius: 10px;  background-color: #4b505c; border: none;" v-clipboard:copy="panel1.acctNo" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>

</div>



<br>

<div style="width:100%;margin-bottom: 20px;align-items: start;display: flex;flex-direction: column;padding: 20px;">
 <span style="font-size: large;color: #ff5722;">{{ $t('common.tip') }}:</span>

 <span style="color:#fff;margin-top: 5px;">{{$t('uc.finance.notice11')}}</span>
</div>


</section>

     </div>
        </TabPane>

    </Tabs>

</div>
<div v-else-if="vertical == 'VND'" >
  <!-- <RadioGroup type="button" v-model="vndRadio" @on-change="switchCurrencyVND">
    <Radio label="USDT" border></Radio>
    <Radio label="CAMPAY" border></Radio>
  </RadioGroup> -->
  <!-- <div style="background-color: #293042;height: 60px;padding-top: 15px;">
  <div style="display: flex;width: 50%;justify-content: space-around;margin-left: 15px;">
    <Button style="background-color: #ff5722;border-radius: 15px;color: aliceblue;border: none;" v-if="usdtactive == true" @click="usdtclick">USDT</Button>
    <Button  style="border-radius: 15px;color: aliceblue;background-color: #4b505c;border: none;" v-else @click="usdtclick">USDT</Button>
   <Button style="background-color: #ff5722;border-radius: 15px;color: aliceblue;border: none;" v-if="campayactive == true" @click="campayclick">CAMPAY</Button>
    <Button  style="border-radius: 15px;color: aliceblue;background-color: #4b505c;border: none;" v-else @click="campayclick">CAMPAY</Button>
</div>
  </div> -->

  <Tabs value='name7' @on-click="choose2">
        <TabPane label="USDT" name="name7">
          <div class="bill_box">

         <section style="margin-top:20px;" v-if=" totalRadio == 'USDT'">

<div >
 <span style="font-size: large;color: #fff;">{{$t('uc.finance.personalassets')}}:  TRC20</span>
</div>

<vue-qr :logo-src="logoSrc"
   :size="191"
   :margin="10"
   :auto-color="true"
   :dot-scale="1"
   :text="panel1.acctNo"
   colorDark="black"
   colorLight="white"
   style="background-color:azure;margin-top: 20px;margin-bottom: 20px;"
/>

<div class="uclass">
<span style="color: #ff5722;font-size: large;">1USDT=1USD</span>
<br>
<span style="color: #fff;">{{$t('uc.finance.swapassets')}}:{{panel1.acctNo}}</span>


<Button style="color: #fff; font-weight: bold; border-radius: 10px;  background-color: #4b505c; border: none;" v-clipboard:copy="panel1.acctNo" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>

</div>



<br>

<div style="width:100%;margin-bottom: 20px;align-items: start;display: flex;flex-direction: column;padding: 20px;">
 <span style="font-size: large;color: #ff5722;">{{ $t('common.tip') }}:</span>

 <span style="color:#fff;margin-top: 5px;">{{$t('uc.finance.notice11')}}</span>
</div>


</section>

     </div>
        </TabPane>
        <TabPane label="CAMPAY" name="name6">
          <section style="margin-top:0px;">

<!-- <RadioGroup v-model="bankCode" style="margin-top: 10px;  color: #8994a3;" @on-change="switchBank">
<Radio v-for="item in bankList" :label="item.bankName" :key="item.bankId" style='margin: 10px;'>
    <span>{{ item.bankName }}</span>
</Radio>

</RadioGroup> -->
<!-- <div style="display: flex;padding: 10px;flex-direction: column;align-items: flex-start;">
<span style="font-size: medium;color: #fff;">{{$t('uc.finance.xx189')}}:</span>
<back-list @select="selectItem" :list="bankList"></back-list>
</div> -->

<Select v-model="backCode" style="width:80%; margin-top: 10px;" :label-in-value="true" @on-change="selectItem1">
<img :src="currentbankImag" width="20" height="20" alt="" slot="prefix" style="margin-right: 10px;">
<Option v-for="item in bankList" :value="item.bankCode" :key="item.value" style="display: flex;align-items: center;" >
<img :src="item.ImageUrl" width="20" height="20" alt="" style="margin-right: 10px;">
{{ item.bankName }}
</Option>
</Select>


<div style="margin-top: 50px;">

<Input v-model="huioneName" type="text" size="large" style="width: 80%;border-radius: 20px;border: none;"  clearable   :placeholder="$t('uc.finance.xx121')" />

</div>

<div style="margin-top: 10px;">

<Input v-model="campayamount" type="text" size="large" @on-change="onKeyYHKNameInput1" style="width: 80%;border-radius: 20px;border: none;"  clearable   :placeholder="$t('uc.finance.notice12345')" />

</div>

<div style="margin-top:40px; margin-bottom: 20px; padding-bottom: 20px;">
<Button style="width:40%; height: 40px; background-color: #ff5722; font-size: medium; border: none;  color: #fff; font-weight: 600;"   @click="campaySubmit">{{$t('uc.forget.save')}}</Button>
</div>

<!-- <img src="../assets/logo/bankcard_sample.png" width="90%" alt=""> -->
</section>
        </TabPane>
    </Tabs>

</div>
<div v-else-if="vertical == 'CNY'">
  <!-- <RadioGroup type="button" v-model="cnyRadio">
        <Radio label="USDT" border></Radio>
  </RadioGroup> -->
  <!-- <div style="background-color: #293042;height: 60px;padding-top: 15px;">
  <div style="display: flex;width: 70%;margin-left: 20px;">
    <Button style="background-color: #ff5722;border-radius: 15px;color: aliceblue;border: none;" v-if="usdtactive == true" @click="usdtclick">USDT</Button>
    <Button  style="border-radius: 15px;color: aliceblue;background-color: #4b505c;border: none;" v-else @click="usdtclick">USDT</Button>
  </div>
</div> -->

<Tabs value='name8'>
        <TabPane label="USDT" name="name8">
          <div class="bill_box">

         <section style="margin-top:20px;">

<div >
 <span style="font-size: large;color: #fff;">{{$t('uc.finance.personalassets')}}:  TRC20</span>
</div>

<vue-qr :logo-src="logoSrc"
   :size="191"
   :margin="10"
   :auto-color="true"
   :dot-scale="1"
   :text="panel1.acctNo"
   colorDark="black"
   colorLight="white"
   style="background-color:azure;margin-top: 20px;margin-bottom: 20px;"
/>

<div class="uclass">
<span style="color: #ff5722;font-size: large;">1USDT=1USD</span>
<br>
<span style="color: #fff;">{{$t('uc.finance.swapassets')}}:{{panel1.acctNo}}</span>


<Button style="color: #fff; font-weight: bold; border-radius: 10px;  background-color: #4b505c; border: none;" v-clipboard:copy="panel1.acctNo" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>

</div>



<br>

<div style="width:100%;margin-bottom: 20px;align-items: start;display: flex;flex-direction: column;padding: 20px;">
 <span style="font-size: large;color: #ff5722;">{{ $t('common.tip') }}:</span>

 <span style="color:#fff;margin-top: 5px;">{{$t('uc.finance.notice11')}}</span>
</div>


</section>

     </div>
        </TabPane>

    </Tabs>



</div>
<div v-else-if="vertical == 'IDR'">
  <!-- <RadioGroup type="button" v-model="idrRadio">
        <Radio label="USDT" border></Radio>
  </RadioGroup> -->
  <!-- <div style="background-color: #293042;height: 60px;padding-top: 15px;">

  <div style="display: flex;width: 70%;margin-left: 20px;">
    <Button style="background-color: #ff5722;border-radius: 15px;color: aliceblue;border: none;" v-if="usdtactive == true" @click="usdtclick">USDT</Button>
    <Button  style="border-radius: 15px;color: aliceblue;background-color: #4b505c;border: none;" v-else @click="usdtclick">USDT</Button>
  </div>
</div> -->
<Tabs value="name9">
        <TabPane label="USDT" name="name9">
          <div class="bill_box">

         <section style="margin-top:20px;" v-if=" totalRadio == 'USDT'">

<div >
 <span style="font-size: large;color: #fff;">{{$t('uc.finance.personalassets')}}:  TRC20</span>
</div>

<vue-qr :logo-src="logoSrc"
   :size="191"
   :margin="10"
   :auto-color="true"
   :dot-scale="1"
   :text="panel1.acctNo"
   colorDark="black"
   colorLight="white"
   style="background-color:azure;margin-top: 20px;margin-bottom: 20px;"
/>

<div class="uclass">
<span style="color: #ff5722;font-size: large;">1USDT=1USD</span>
<br>
<span style="color: #fff;">{{$t('uc.finance.swapassets')}}:{{panel1.acctNo}}</span>


<Button style="color: #fff; font-weight: bold; border-radius: 10px;  background-color: #4b505c; border: none;" v-clipboard:copy="panel1.acctNo" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>

</div>



<br>

<div style="width:100%;margin-bottom: 20px;align-items: start;display: flex;flex-direction: column;padding: 20px;">
 <span style="font-size: large;color: #ff5722;">{{ $t('common.tip') }}:</span>

 <span style="color:#fff;margin-top: 5px;">{{$t('uc.finance.notice11')}}</span>
</div>


</section>

     </div>
        </TabPane>

    </Tabs>


</div>
<div v-else-if="vertical == 'JPY'">
  <!-- <RadioGroup type="button" v-model="jpyRadio">
        <Radio label="USDT" border></Radio>
  </RadioGroup> -->
  <!-- <div style="background-color: #293042;height: 60px;padding-top: 15px;">

  <div style="display: flex;width: 70%;margin-left: 20px;">
    <Button style="background-color: #ff5722;border-radius: 15px;color: aliceblue;border: none;" v-if="usdtactive == true" @click="usdtclick">USDT</Button>
    <Button  style="border-radius: 15px;color: aliceblue;background-color: #4b505c;border: none;" v-else @click="usdtclick">USDT</Button>
</div>

</div> -->

<Tabs value="name10">

        <TabPane label="USDT" name="name10">
          <div class="bill_box">

         <section style="margin-top:20px;" v-if=" totalRadio == 'USDT'">

<div >
 <span style="font-size: large;color: #fff;">{{$t('uc.finance.personalassets')}}:  TRC20</span>
</div>

<vue-qr :logo-src="logoSrc"
   :size="191"
   :margin="10"
   :auto-color="true"
   :dot-scale="1"
   :text="panel1.acctNo"
   colorDark="black"
   colorLight="white"
   style="background-color:azure;margin-top: 20px;margin-bottom: 20px;"
/>

<div class="uclass">
<span style="color: #ff5722;font-size: large;">1USDT=1USD</span>
<br>
<span style="color: #fff;">{{$t('uc.finance.swapassets')}}:{{panel1.acctNo}}</span>


<Button style="color: #fff; font-weight: bold; border-radius: 10px;  background-color: #4b505c; border: none;" v-clipboard:copy="panel1.acctNo" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>

</div>



<br>

<div style="width:100%;margin-bottom: 20px;align-items: start;display: flex;flex-direction: column;padding: 20px;">
 <span style="font-size: large;color: #ff5722;">{{ $t('common.tip') }}:</span>

 <span style="color:#fff;margin-top: 5px;">{{$t('uc.finance.notice11')}}</span>
</div>


</section>

     </div>
        </TabPane>

    </Tabs>
</div>
<div v-else-if="vertical == 'KRW'">
  <!-- <RadioGroup type="button" v-model="krwRadio">
        <Radio label="USDT" border></Radio>
  </RadioGroup> -->
  <!-- <div style="background-color: #293042;height: 60px;padding-top: 15px;">

  <div style="display: flex;width: 70%;margin-left: 20px;">
    <Button style="background-color: #ff5722;border-radius: 15px;color: aliceblue;border: none;" v-if="usdtactive == true" @click="usdtclick">USDT</Button>
    <Button  style="border-radius: 15px;color: aliceblue;background-color: #4b505c;border: none;" v-else @click="usdtclick">USDT</Button>
   </div>
</div> -->

<Tabs value="name11">

        <TabPane label="USDT" name="name11">
          <div class="bill_box">

         <section style="margin-top:20px;" v-if=" totalRadio == 'USDT'">

<div >
 <span style="font-size: large;color: #fff;">{{$t('uc.finance.personalassets')}}:  TRC20</span>
</div>

<vue-qr :logo-src="logoSrc"
   :size="191"
   :margin="10"
   :auto-color="true"
   :dot-scale="1"
   :text="panel1.acctNo"
   colorDark="black"
   colorLight="white"
   style="background-color:azure;margin-top: 20px;margin-bottom: 20px;"
/>

<div class="uclass">
<span style="color: #ff5722;font-size: large;">1USDT=1USD</span>
<br>
<span style="color: #fff;">{{$t('uc.finance.swapassets')}}:{{panel1.acctNo}}</span>


<Button style="color: #fff; font-weight: bold; border-radius: 10px;  background-color: #4b505c; border: none;" v-clipboard:copy="panel1.acctNo" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>

</div>



<br>

<div style="width:100%;margin-bottom: 20px;align-items: start;display: flex;flex-direction: column;padding: 20px;">
 <span style="font-size: large;color: #ff5722;">{{ $t('common.tip') }}:</span>

 <span style="color:#fff;margin-top: 5px;">{{$t('uc.finance.notice11')}}</span>
</div>


</section>

     </div>
        </TabPane>

    </Tabs>

</div>
<div v-else-if="vertical == 'THB'">
  <!-- <RadioGroup type="button" v-model="thbRadio">
        <Radio label="USDT" border></Radio>
  </RadioGroup> -->
  <!-- <div style="background-color: #293042;height: 60px;padding-top: 15px;">

  <div style="display: flex;width: 70%;margin-left: 20px;">
    <Button style="background-color: #ff5722;border-radius: 15px;color: aliceblue;border: none;" v-if="usdtactive == true" @click="usdtclick">USDT</Button>
    <Button  style="border-radius: 15px;color: aliceblue;background-color: #4b505c;border: none;" v-else @click="usdtclick">USDT</Button>
  </div>
</div> -->

<Tabs value="name12">
        <TabPane label="USDT" name="name12">
          <div class="bill_box">

         <section style="margin-top:20px;">

<div >
 <span style="font-size: large;color: #fff;">{{$t('uc.finance.personalassets')}}:  TRC20</span>
</div>

<vue-qr :logo-src="logoSrc"
   :size="191"
   :margin="10"
   :auto-color="true"
   :dot-scale="1"
   :text="panel1.acctNo"
   colorDark="black"
   colorLight="white"
   style="background-color:azure;margin-top: 20px;margin-bottom: 20px;"
/>

<div class="uclass">
<span style="color: #ff5722;font-size: large;">1USDT=1USD</span>
<br>
<span style="color: #fff;">{{$t('uc.finance.swapassets')}}:{{panel1.acctNo}}</span>


<Button style="color: #fff; font-weight: bold; border-radius: 10px;  background-color: #4b505c; border: none;" v-clipboard:copy="panel1.acctNo" v-clipboard:success="onCopy" v-clipboard:error="onError" href="javascript:;" id="copyBtn">{{$t('uc.finance.copy')}}</Button>

</div>



<br>

<div style="width:100%;margin-bottom: 20px;align-items: start;display: flex;flex-direction: column;padding: 20px;">
 <span style="font-size: large;color: #ff5722;">{{ $t('common.tip') }}:</span>

 <span style="color:#fff;margin-top: 5px;">{{$t('uc.finance.notice11')}}</span>
</div>


</section>

     </div>
        </TabPane>

    </Tabs>

</div>
</div>



    <!-- model1 -->
    <Modal v-model="modal1" width="360">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-mail" size="20" color="#00b5f6;" />
        <span>{{$t('uc.finance.recharge.coderechargetip')}}</span>
      </p>
      <div style="text-align:center">
        <Form ref="formValidate" :label-width="0">
          <FormItem>
            <Input v-model="withdrawCode" :placeholder="$t('uc.finance.recharge.coderechargetip')"></Input>
          </FormItem>
        </Form>
      </div>
      <div slot="footer">
        <Button type="primary" size="large" long @click="getCodeInfo">{{$t('uc.finance.withdraw.submit')}}</Button>
      </div>
    </Modal>

    <!-- model2 -->
    <Modal v-model="modal2" width="360">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-mail" size="20" color="#00b5f6;" />
        <span>{{$t('uc.finance.recharge.rechargeconfirm')}}</span>
      </p>
      <div style="text-align:center">
        <p><span>{{$t('uc.finance.recharge.symbol')}}: </span><span>{{withdrawCodeInfo.coin.unit}}</span></p>

        <p><span>{{$t('uc.finance.recharge.amount')}}: </span><span>{{withdrawCodeInfo.withdrawAmount}}</span></p>

      </div>
      <div slot="footer">
        <Button type="primary" size="large" long @click="submitCode">{{$t('uc.finance.withdraw.submit')}}</Button>
      </div>
    </Modal>


    <Modal
        :title="$t('common.pleaseselectCurrency')"
        v-model="modal8"
        :closable="false"
        :mask-closable="false">
        <div slot="footer">
            <Button type="primary" @click="confirmCurrency">{{$t("common.ok")}}</Button>
        </div>
        <RadioGroup v-model="vertical" vertical>
        <Radio v-for="item in currencyList" :label="item.ccy" :key="item.id" style="margin-top: 10px;display: flex; align-items: center;">
            <img :src=item.coinImg alt="" width="25">
            <span>{{ item.ccy }}</span>
        </Radio>

    </RadioGroup>
    </Modal>
    <Spin fix v-show="eloading" style="background:transparent;">
      <div class="loader">
          <img src="../assets/logo/loading.svg" alt="">
      </div>
    </Spin>
  </div>
</template>
<script>

import VueQr from 'vue-qr'
import * as imageConversion from 'image-conversion'
// import BackList from '../components/back-list.vue'

export default {

    components: {
      // BackList,
VueQr
  },
  data() {
    return {
      modal1: false,
      modal2: false,
      modal8: false,
      totalRadio:'',
      usdRadio: '',
      usdtRadio:'',
      vndRadio:'',
      cnyRadio:'',
      idrRadio: '',
      krwRadio: '',
      thbRadio: '',
      jpyRadio: '',
      vertical: '',
      currenyImg: '',
      withdrawCode: "",
      campayamount: '',
      fundpwd: "",
      userNo: '',
      accountType: 0,
      memoCode: "",
      huioneName:'',
      huionephone:'',
      huioneamount : '',
      minRechargeAmount:"0.001",
      isShowGetAddress: false,
      isShowEwm: false,
      dataCount: 0,
      currentbankImag: '',
      loading: false,
      huioneinfo:{},
      bankCode:'',
      qrcode: {
        value: "",
        size: 220,
        coinName: "",
        unit: ""
      },
      xxxyx: 'name1',
      eloading: false,
      huioneNameaddress:'',
      model1:'USDT',
      uploadUrl: this.host + '/upload/app/v1/uploadSingleImg',
       windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
panel1:{
  acctNo:''
},
panel2:'',

      coinType: "",
      coinList: [],
      tableRecharge: [],
      currencyList:[],
      allTableRecharge: [],
      bankList:[],
      backCode: '',
      uploadHeaders: { "x-auth-token": localStorage.getItem("TOKEN") },
      frontCardImg: '',

      withdrawCodeInfo: {
        coin: {
          unit: ""
        }
      },
      value1:'',

      tabBarValue: '1',

        logoSrc: '',


  usdtactive:false,
  huioneactive:false,
  campayactive:false

    };
  },
  methods: {

    usdtclick(){
      this.usdtactive = true
      this.huioneactive = false
      this.campayactive = false
      this.totalRadio = 'USDT'
    },
    huioneclick(){
      this.usdtactive = false
      this.huioneactive = true
      this.campayactive = false
      this.totalRadio = 'HUIONE'
    },
    campayclick(){
      this.usdtactive = false
      this.huioneactive = false
      this.campayactive = true
      this.totalRadio = 'CAMPAY'
    },

    choose1(data){
console.log('sdfsdf '+data);
    },
    choose2(data){
console.log('xccvxcv '+data);
    },


    campaySubmit(){

      if (this.huioneName == '') {
        this.$Message.error(this.$t('uc.finance.xx100'))
        return
      }
      let that = this
      let param = {}
      let num = this.campayamount.replace(/\D/g,'');
    console.log('string without commas', num);

    let num2 = parseInt(num);
    console.log('numeric', num2);

      param.ccy = this.vertical
param.depositName = this.huioneName
param.payChannal = 'campay'
param.bankCode = this.backCode
param.amount = num2

console.log('bankcode ', this.backCode);


this.eloading = true
      this.$http
        .post(this.host + this.api.uc.addDepositOrder, param)
        .then(response => {
          var resp = response.body;
          that.eloading = false
          if (resp.code == "0000") {
          // 銀行卡 0 匯旺 1 usdt 2 大象 3

// {
//   "status": "0",
//   "code": "SUCCESS",
//   "message": "Successfully",
//   "transId": "57f6ebf034714d53aafbbd8df73fc3ce",
//   "partnerTransId": "1150771113211461632",
//   "refId": "3FC3CE",
//   "wopUrl": "https://jbpgw.pay1s.net/wop?language=en&token=b7cff4d10af88e0dbc1526b9a5254d5cb82f01e90f9df2e0588380f42fb00d66"
// }
          // this.$router.push({ path: '/thirdRecharge', query: { gpage: resp } })

          let xx = JSON.parse(resp.data)

          window.open(xx.wopUrl,"_self")
          // this.$router.push({ path: '/GamePage', query: { gpage: resp.wopUrl, gid:'-1' } })
          } else {
            that.$Message.error(resp.mesg);
          }
        });

    },
    switchBank(data){
      this.clearValues()
      this.bankList.forEach(element => {
        if (element.bankName == data) {
          this.backCode = element.bankCode
        }
      });
    },

    switchCurrency(data){
      this.totalRadio = data
      if (data == 'CAMPAY') {
        this.getBankList()
      }
    },

    switchCurrencyVND(data){
      this.totalRadio = data
      if (data == 'CAMPAY') {
        this.getBankList()
      }
    },

    confirmCurrency(){

      this.clearValues()
this.modal8 = false
this.usdtactive = true
this.campayactive = false
this.huioneactive = false
if (this.vertical == 'USDT') {
    this.currenyImg = require('@/assets/logo/usdt.png');
    this.usdtRadio = this.totalRadio = 'USDT'

   }else if (this.vertical == 'USD') {
    this.currenyImg = require('@/assets/logo/usa.png')
    this.usdRadio = this.totalRadio = 'USDT'
    this.getBankList()
   }else if (this.vertical == 'VND') {
    this.currenyImg = require('@/assets/logo/vietnam.png')
    this.vndRadio =  this.totalRadio = 'USDT'
    this.getBankList()
   }else if (this.vertical == 'CNY') {
    this.currenyImg = require('@/assets/logo/china.png')
   this.totalRadio = 'USDT'
   this.cnyRadio = 'USDT'
   }
   else if (this.vertical  == 'IDR') {
    this.idrRadio = this.totalRadio = 'USDT'

    this.currenyImg = require('@/assets/logo/indiaN.png')
   }
   else if (this.vertical  == 'JPY') {
    this.jpyRadio =this.totalRadio = 'USDT'

    this.currenyImg = require('@/assets/logo/japan.png')
   }
   else if (this.vertical  == 'KRW') {
    this.krwRadio =this.totalRadio = 'USDT'

    this.currenyImg = require('@/assets/logo/korea.png')
   }else if (this.vertical  == 'THB') {
    this.thbRadio =this.totalRadio = 'USDT'

    this.currenyImg = require('@/assets/logo/thai.png')
   }
    },
    cancelCurrency(){
this.model8 = false
    },

    popUP(){
      this.modal8 = true
    },

    beforeUpload(data) {
      return new Promise((resolve) => {

        // console.log('压缩前', file) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
        imageConversion.compressAccurately(data, 100).then(res => { // console.log(res)
          res = new File([res], 'recharge', { type: res.type, lastModified: Date.now() })
          resolve(res)
        })
      })


      // if (data && data.size >= 1024000 * 2) {
      //   this.$Message.error("上传图片大小不能超过2M");
      //   return false;
      // }
    },
    getPayAddress(){
let self = this
let param = {}
param.status = 0
      this.$http
        .get(this.host + '/funds/getAddress', param)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
          // 銀行卡 0 匯旺 1 usdt 2 大象 3
let hereData = resp.data
hereData.forEach(element => {
  switch (element.addressType) {
    case 0:

      break;
    case 1:
    self.huioneinfo = element
      break
    case 2:
      break
    case 3:
      break
    default:
      break;
  }
});

          } else {
            self.$Message.error(resp.mesg);
          }
        });

    },


goback() {
      //获取个人安全信息
      this.$router.back(-1)

    },

getUdun(){
  let that = this
  this.$http.get(this.host + this.api.uc.udun).then(response => {
          var resp = response.body;
          this.loading = false;

          if (resp.code == "0000") {
            that.panel1.acctNo = resp.data.walletAddress
           console.log('');
          } else {
            that.$Message.error(resp.message);
          }
        });
},

    getBankList(){
      let that = this
      let param = {
        'transCurrency':this.vertical,
        'language':this.$i18n.locale
      }

      this.$http.get(this.host + this.api.uc.cambodiaDepositBanks, {params: param}).then(response => {
          var resp = response.body;
          this.loading = false;

          if (resp.code == "SUCCESS") {
            this.bankList = resp.banks
            this.bankList.forEach(element => {
              // switch (element.bankId) {
              //   case "KHQR":
              //     element.ImageUrl = require('@/assets/logo/zz5.png')
              //     element.isactive = false
              //     break;
              //   case "EMONEY":
              //     element.ImageUrl = require('@/assets/logo/zz6.png')
              //     element.isactive = false
              //     break;
              //   case "WING":
              //     element.ImageUrl = require('@/assets/logo/wing11.png')
              //     element.isactive = false
              //     break;
              //   case "ABA":
              //     element.ImageUrl = require('@/assets/logo/zz7.png')
              //     element.isactive = false
              //     break;
              //   case "ACLEDA":
              //     element.ImageUrl = require('@/assets/logo/zz2.png')
              //     element.isactive = false
              //     break;
              //   case "970436":
              //     element.ImageUrl = require('@/assets/logo/zz1.png')
              //     element.isactive = false
              //     break;
              //   case "970422":
              //     element.ImageUrl = require('@/assets/logo/zz4.png')
              //     element.isactive = false
              //     break;
              //   case "970407":
              //     element.ImageUrl = require('@/assets/logo/zz3.png')
              //     element.isactive = false
              //     break;
              //   default:
              //     break;
              // }
            });

          } else {
            if (resp.message) {
              that.$Message.error(resp.message);

            }
          }
        });
    },


    onKeyYHKNameInput1() {
       let value = '' + this.campayamount
       // console.log(this.body.ccyScale,"币种精度")
       let data = 2 //币种精度
       value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), '$1$2.$3') // 只能输入data个小数
        if (value.indexOf('.') < 0 && value != '') {
         // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
         value = parseFloat(value);
        }
        this.$nextTick(function(){
          this.campayamount = Number(value).toLocaleString()

        })
     },

     selectItem1(item){
      // this.backCode = item.value
      // if (item.label.includes('KHQR')) {
      //   this.currentbankImag = require('@/assets/logo/zz5.png')
      // }else if (item.label.includes('eMoney')) {
      //   this.currentbankImag = require('@/assets/logo/zz6.png')
      // }else if (item.label.includes('Wing Bank')) {
      //   this.currentbankImag = require('@/assets/logo/wing11.png')
      // }else if (item.label.includes('ABA Bank')) {
      //   this.currentbankImag = require('@/assets/logo/zz7.png')
      // }else if (item.label.includes('ACLEDA Bank')) {
      //   this.currentbankImag = require('@/assets/logo/zz2.png')
      // }else if (item.label.includes('VIETCOMBANK')) {
      //   this.currentbankImag = require('@/assets/logo/zz1.png')
      // }else if (item.label.includes('MBBANK')) {
      //   this.currentbankImag = require('@/assets/logo/zz4.png')
      // }else if (item.label.includes('TECHCOMBANK')) {
      //   this.currentbankImag = require('@/assets/logo/zz3.png')
      // }
      // switch (item.label) {
      //           case "KHQR":
      //            this.currentbankImag = require('@/assets/logo/KHQR_1.png')

      //             break;
      //           case "EMONEY":
      //           this.currentbankImag = require('@/assets/logo/eMONER_1.png')

      //             break;
      //           case "WING":
      //           this.currentbankImag = require('@/assets/logo/WINGBANK_1.png')

      //             break;
      //           case "ABA":
      //           this.currentbankImag = require('@/assets/logo/ABABANK_1.png')

      //             break;
      //           case "ACLEDA":
      //           this.currentbankImag = require('@/assets/logo/ACLEDA_1.png')

      //             break;
      //           case "970436":
      //           this.currentbankImag = require('@/assets/logo/77.png')
      //             break;
      //           case "970422":
      //           this.currentbankImag = require('@/assets/logo/88.png')

      //                       break;
      //           case "970407":
      //           this.currentbankImag = require('@/assets/logo/66.png')
      //             break;


      //           default:
      //             break;
      //         }

     },

    //  selectItem(item){

    //   for (let index = 0; index < this.bankList.length; index++) {
    //     const element = this.bankList[index];

    //     let temp = element
    //     switch (element.bankId) {
    //             case "KHQR":
    //               // element.ImageUrl = require('@/assets/logo/KHQR.png')
    //               // this.$set(element, 'ImageUrl', require('@/assets/logo/KHQR.png') )
    //       temp.ImageUrl = require('@/assets/logo/KHQR.png')
    //       this.$set(this.bankList, index, temp )
    //               break;
    //             case "EMONEY":

    //       temp.ImageUrl = require('@/assets/logo/eMONER.png')
    //       this.$set(this.bankList, index, temp )
    //               break;
    //             case "WING":
    //               temp.ImageUrl = require('@/assets/logo/WINGBANK.png')
    //       this.$set(this.bankList, index, temp )
    //               break;
    //             case "ABA":
    //               temp.ImageUrl = require('@/assets/logo/ABABANK.png')
    //       this.$set(this.bankList, index, temp )
    //               break;
    //             case "ACLEDA":
    //               temp.ImageUrl = require('@/assets/logo/ACLEDA.png')
    //       this.$set(this.bankList, index, temp )
    //               break;
    //             case "970436":
    //               temp.ImageUrl = require('@/assets/logo/7.png')
    //       this.$set(this.bankList, index, temp )
    //               break;
    //             case "970422":
    //               temp.ImageUrl = require('@/assets/logo/8.png')
    //       this.$set(this.bankList, index, temp )
    //                         break;
    //             case "970407":
    //               temp.ImageUrl = require('@/assets/logo/6.png')
    //       this.$set(this.bankList, index, temp )
    //               break;
    //             default:
    //               break;
    //           }
    //   }
    //         switch (item.bankId) {
    //             case "KHQR":
    //             item.ImageUrl = require('@/assets/logo/KHQR_1.png')
    //               break;
    //             case "EMONEY":
    //             item.ImageUrl = require('@/assets/logo/eMONER_1.png')

    //               break;
    //             case "WING":
    //             item.ImageUrl = require('@/assets/logo/WINGBANK_1.png')

    //               break;
    //             case "ABA":
    //             item.ImageUrl = require('@/assets/logo/ABABANK_1.png')

    //               break;
    //             case "ACLEDA":
    //             item.ImageUrl = require('@/assets/logo/ACLEDA_1.png')

    //               break;
    //             case "970436":
    //             item.ImageUrl = require('@/assets/logo/77.png')
    //               break;
    //             case "970422":
    //             item.ImageUrl = require('@/assets/logo/88.png')

    //                         break;
    //             case "970407":
    //             item.ImageUrl = require('@/assets/logo/66.png')
    //               break;


    //             default:
    //               break;
    //           }

    //           this.backCode = item.bankCode
    //  },

onKeyYHKNameInput() {
       let value = '' + this.huioneamount
       // console.log(this.body.ccyScale,"币种精度")
       let data = 2 //币种精度
       value = value.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(new RegExp(`^(\\-)*(\\d+)\\.(\\d{${data}}).*$`), '$1$2.$3') // 只能输入data个小数
        if (value.indexOf('.') < 0 && value != '') {
         // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
         value = parseFloat(value);
        }
        this.$nextTick(function(){
          this.huioneamount = Number(value).toLocaleString()

        })
     },
    huioneSubmit(){

if (!this.huionephone || !this.frontCardImg) {
  this.$Message.error(this.$t('uc.finance.notice123'))
  return
}
      var self = this;
     let param = {}
param.ccy = this.huioneinfo.ccy
param.depositName = this.huioneName
param.depositAddress = this.huionephone
param.remark = this.frontCardImg
param.payChannal = 'huione'

var num = this.huioneamount.replace(/\D/g,'');
    console.log('string without commas', num);

    var num2 = parseInt(num);
    console.log('numeric', num2);

param.amount = num2
// if (param.amount &&  param.amount > 0) {
//   param.walletAddress = this.huioneinfo.walletAddress
// param.walletName = this.huioneinfo.walletName

// }else{
//   this.$Message.error(this.$t('uc.finance.notice12345'))
//   return
// }
this.eloading = true


     this.$http
       .post(this.host + this.api.uc.addDepositOrder, param)
       .then(response => {
         let resp = response.body;
         self.eloading = false
         if (resp.code == "0000") {

         self.$Message.success(this.$t('uc.finance.notice1234'))
         self.clearValues()
         } else {
           self.$Message.error(resp.mesg);
         }
       });
    },

    clearValues(){
      this.huioneName = ''
this.huionephone = ''
this.frontCardImg = ''
this.huioneamount = 0
this.campayamount = 0
this.backCode = ''
    },

    isPicNull(val) {
      if (val == null) {
        return true
      }

      return false;
    },

    getMemberInfo() {
      //获取个人安全信息
      let self = this;

      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
          let current =   self.coinType = resp.data.remark
          self.usdtactive = true
   if (current == 'USDT') {
    self.vertical = 'USDT'
    self.currenyImg = require('@/assets/logo/usdt.png');
    self.usdtRadio = self.totalRadio = 'USDT'

   }else if (current == 'USD') {
    self.vertical = 'USD'
    self.currenyImg = require('@/assets/logo/usa.png')
    self.usdRadio = self.totalRadio = 'USDT'
   }else if (current == 'VND') {
    self.vertical = 'VND'
    self.currenyImg = require('@/assets/logo/vietnam.png')
    self.vndRadio =  self.totalRadio = 'USDT'

   }else if (current == 'CNY') {
    self.vertical = 'CNY'
    self.currenyImg = require('@/assets/logo/china.png')
    self.cnyRadio =  self.totalRadio = 'USDT'

   }else if (current == 'IDR') {
    self.vertical = 'IDR'
    self.currenyImg = require('@/assets/logo/indiaN.png')
    self.idrRadio =  self.totalRadio = 'USDT'

   }
   else if (current == 'JPY') {
    self.vertical = 'JPY'
    self.currenyImg = require('@/assets/logo/japan.png')
    self.jpyRadio =  self.totalRadio = 'USDT'

   }
   else if (current == 'KRW') {
    self.vertical = 'KRW'
    self.currenyImg = require('@/assets/logo/korea.png')
    self.krwRadio =  self.totalRadio = 'USDT'

   }
   else if (current == 'THB') {
    self.vertical = 'THB'
    self.currenyImg = require('@/assets/logo/thai.png')
    self.thbRadio =  self.totalRadio = 'USDT'

   }

   self.getBankList()

          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },

    handleTabBarChange(value) {
			console.log('LyTabBar change:', value);

              if (value == 'about') {
                this.$router.push("/About");

              }else if (value == 'withdraw') {
                this.$router.push("/Withdraw");

              }

		},

    frontHandleSuccess(res, file,fileList) {
      this.$refs.upload1.fileList=[fileList[fileList.length-1]];
      if (res.code == '0000') {
        this.frontCardImg = this.imgPreview = res.data.url;
      } else {
        this.$Message.error(res.message);
      }
    },

    caclHeight() {
      // this.windowHeight += 200
      console.log("wwwe" + this.windowHeight)
      return `background-color: #0b1520`
    },
    getCurrentCoinRecharge() {
      if (this.coinType != "") {
        var temp = [];
        for (var i = 0; i < this.allTableRecharge.length; i++) {
          if (this.allTableRecharge[i].symbol == this.coinType) {
            temp.push(this.allTableRecharge[i]);
          }
        }
        this.tableRecharge = temp;
      } else {
        this.tableRecharge = this.allTableRecharge;
      }
    },
    showEwm() {
      this.isShowEwm = !this.isShowEwm;
    },
    onCopy(e) {
      this.$Message.success(
        this.$t("uc.finance.copysuccess") + e.text
      );
    },
    onError() {
      this.$Message.error(this.$t("uc.finance.copyfailed"));
    },

    openCodeModal(){
      this.modal1 = true;
      this.withdrawCode = "";
    },
    getCodeInfo(){
      if(this.withdrawCode == "") {
        this.$Message.warning(this.$t("uc.finance.recharge.coderechargetip"));
        return;
      }
      let param = {};
      param["withdrawCode"] = this.withdrawCode;

      this.$http
        .post(this.host + "/uc/withdrawcode/apply/info", param)
        .then(response => {
          var resp = response.body;
          if (resp.code == 0) {
            this.withdrawCodeInfo = resp.data;

            this.modal1 = false;
          } else {
            this.$Message.error(resp.message);
          }
        });
      this.modal1 = false;
      this.modal2 = true;
    },
    submitCode(){
      if(this.withdrawCode == "") {
        this.$Message.warning(this.$t("uc.finance.recharge.coderechargetip"));
        return;
      }
      let param = {};
      param["withdrawCode"] = this.withdrawCode;

      this.$http
        .post(this.host + "/uc/withdrawcode/apply/recharge", param)
        .then(response => {
          var resp = response.body;
          if (resp.code == 0) {
            this.$Message.success(this.$t("uc.finance.recharge.rechargesuccess"));
            this.modal1 = false;
          } else {
            this.$Message.error(resp.message);
          }
        });
      this.modal2 = false;
    },
    resetAddress(){
      if(this.qrcode.value == "" || this.qrcode.value == null || this.qrcode.value == undefined){
        this.$Spin.show({
            render: (h) => {
                return h('div', [
                    h('Icon', {
                        'class': 'demo-spin-icon-load',
                        props: {
                            type: 'ios-loading',
                            size: 18
                        }
                    }),
                    h('div', {style:{
                      fontSize: "12px",
                      marginTop: "8px"
                    }}, this.$t('uc.finance.recharge.gettingaddress'))
                ])
            }
        });
        let params = {};
        params["unit"] = this.qrcode.unit;

      }
    },


    getMoneyback(){
    let self = this;
    this.$http
        .post(this.host + this.api.uc.apply)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
            // this.getMember();
            // self.$Message.success(resp.mesg);
          } else {
            self.$Message.error(resp.mesg);
          }
        });
  },

  getMember() {
      //获取个人安全信息
      var self = this;

      this.$http
        .post(self.host + this.api.uc.personalWallet)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
          self.currencyList = resp.data
          self.currencyList.forEach(element => {
            if (element.ccy == 'USDT') {
    element.coinImg = require('@/assets/logo/usdt.png');
   }else if (element.ccy == 'USD') {
    element.coinImg = require('@/assets/logo/usa.png')
   }else if (element.ccy == 'VND') {
    element.coinImg = require('@/assets/logo/vietnam.png')
   }else if (element.ccy == 'CNY') {
    element.coinImg = require('@/assets/logo/china.png')
   }
   else if (element.ccy == 'IDR') {
    element.coinImg = require('@/assets/logo/indiaN.png')
   }
   else if (element.ccy == 'JPY') {
    element.coinImg = require('@/assets/logo/japan.png')
   }
   else if (element.ccy == 'KRW') {
    element.coinImg = require('@/assets/logo/korea.png')
   }else if (element.ccy == 'THB') {
    element.coinImg = require('@/assets/logo/thai.png')
   }
            });

          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },
  },
  created() {


    this.getMember()
    this.getPayAddress()

    this.getMemberInfo()
    this.getUdun()

  },

};
</script>

<style scoped lang="scss">


.celllall{
  width: 100%;

  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // color: #a2a2a1;
  // font-size: 18px;
  margin-top: 40px;
  // margin-left: 20px;
}

.uclass1{
  color:rgb(133, 128, 128);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  padding-right: 20px;
  padding-left: 10px;
}
.uclass {
  width: 100%;
  display: flex;
padding: 20px;
  flex-direction: column;
  align-items: start;
}
.box_recharge{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  align-items: center;
justify-content: space-between;
  color: $color-text-tag;
  z-index: 200;
   width: 100%;
}



.body{
  margin-top: 45px;
  color: #8994a3;
}

/deep/ .ivu-input{
  border-radius: 8px;
  border: 1px solid #2f2f2f;
  background-color: #1B2C38;
  color: #fff;
}

/deep/ .ivu-upload .ivu-upload-select{
  height: 100px;
}

/deep/ .ivu-btn .ivu-btn-default{
  height: 100px;
}

.bill_box {
  width: 96%;
  height: auto;
  // background: url(../assets/netmage/hhss.png);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: 100% 100% ;
  margin-left: 2%;
// overflow: hidden;
}

.header{
  position: fixed;
  top: 0;
  left: 0;
	right: 0;
  height: 60px;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items:center;
  background:url(../assets/logo/barBottom.png);
  transform: rotate(0.5turn);
}

.acc_sc{
  width: 40%;
  margin-top: 40px;
  height: 80px;
  background-color: #fff;
  border-radius: 10px;
  padding-top: 6%;
  margin-left: 30%;
}





.withdraw{
  background-color: $color-background;
  height: $full-page;
  position: relative;
  overflow: hidden;
  text-align: center;
}

/deep/ .ivu-tabs-bar{
  border-bottom: 0px solid #dcdee2;
  margin-bottom: 10px;
  background-color: #1B2C38;
}

/deep/ .ivu-tabs-nav-container{
  font-size: 12px;
}

/deep/ .ivu-tabs-nav  .ivu-tabs-tab-active{
  color: #d2b965;
}


/deep/ .ivu-tabs-tab{
  color: #fff;
  padding: 12px 16px
}



/deep/ .ivu-tabs-ink-bar{
  background-color: #d2b965;
}


</style>

<!-- <style scoped lang="less">

// class="ivu-input ivu-input-default"
  /deep/ .ivu-input{
  background: transparent;
  border: 0;
  border-radius: 25px;

  color: #898989
}



</style> -->
