<script>
export default {
    name: "walletSetup",
    data() {
        const validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入资金密码'));
            } else {
                if (this.formValidate.jbPassword !== '') {
                    // 对第二个密码框单独验证
                    this.$refs.formValidate.validateField('jbPasswordSure');
                }
                callback();
            }
        };
        const validatePassCheck = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('输入确认资金密码'));
            } else if (value !== this.formValidate.jbPassword) {
                callback(new Error('两次密码不一致'));
            } else {
                callback();
            }
        };
        return {
            userInfo: null,
            formValidate: {
                realName: '',
                birthday: '',
                jbPassword: '',
                jbPasswordSure: ''
            },
            ruleValidate: {
                // realName: [
                //     {required: true, message: '姓名不可为空', trigger: 'blur'}
                // ],
                // birthday: [
                //     {required: true, message: '姓名不可为空', trigger: 'blur'}
                // ],
                jbPassword: [
                    {validator: validatePass, trigger: 'blur'}
                ],
                jbPasswordSure: [
                    {validator: validatePassCheck, trigger: 'blur'}
                ],
            }
        }
    },
    mounted() {
        if (localStorage.getItem('MEMBER')) {
            this.userInfo = JSON.parse(localStorage.getItem('MEMBER'))
            console.log(this.userInfo)
        }
    },
    methods: {
        getMemberInfo() {
            let self = this;
            this.$http
                .post(this.host + this.api.uc.memberInfo)
                .then(response => {
                    var resp = response.body;
                    if (resp.code == "0000") {
                        localStorage.setItem("MEMBER", JSON.stringify(null));
                        self.$store.commit("setMember", resp.data);
                        self.$router.replace({path: '/'})
                    } else {
                        self.$Message.error(resp.mesg);
                    }
                });
        },
        setWalletPwd(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // this.$Message.success('ok');
                } else {
                    this.$Message.error('请按照提示填写');
                }
            })
            delete this.formValidate.jbPasswordSure
            let param = {...this.formValidate, userNo: Number(this.userInfo.userNo)}
            this.$http.post(this.host + this.api.uc.updatePersonalInfo, param).then(response => {
                const resp = response.body;
                if (resp.code == '0000') {
                    // this.member.huioneName = this.huioneName;
                    // this.$store.commit("setMember", this.member);
                    // this.$router.push({path:'/'})
                    this.getMemberInfo()
                } else {
                    this.$Message.error(resp.mesg);
                }
            });
        },
        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>

<template>
    <div class="walletSet">
        <div class="top">
            <div class="logo">
                <img src="../assets/logo/logojb1.png" alt="">
            </div>
            <div class="withdraw" @click="goBack">退出</div>
        </div>
        <div class="line">
            <div class="left"></div>
            <div class="right"></div>
        </div>
        <div class="txt">
            <div class="txt1">确认您的详细信息</div>
            <div class="txt2">请填写您的详细信息并确认您的身份以解锁附加服务。 所提供的信息都是私密且安全的。</div>
        </div>
        <div class="con">
            <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
                <label class="txt-label" for="realName">真实姓名</label>
                <FormItem label="" prop="realName">
                    <Input v-model="formValidate.realName" placeholder="姓名"></Input>
                </FormItem>
                <label class="txt-label" for="birthday">您的生日</label>
                <FormItem label="" prop="birthday">
                    <Input v-model="formValidate.birthday" placeholder="生日"></Input>
                </FormItem>
                <label class="txt-label" for="jbPassword">资金密码</label>
                <FormItem label="" prop="jbPassword">
                    <Input v-model="formValidate.jbPassword" placeholder="资金密码"></Input>
                </FormItem>
                <label class="txt-label" for="jbPasswordSure">确认资金密码</label>
                <FormItem label="" prop="jbPasswordSure">
                    <Input v-model="formValidate.jbPasswordSure" placeholder="确认资金密码"></Input>
                </FormItem>
                <FormItem>
                    <!--                    <Button type="primary" @click="handleSubmit('formValidate')">Submit</Button>-->
                    <!--                    <Button @click="handleReset('formValidate')" style="margin-left: 8px">保存然后继续</Button>-->
                    <Button class="btn" type="primary" @click="setWalletPwd('formValidate')">保存然后继续</Button>
                </FormItem>
            </Form>

        </div>
    </div>
</template>

<style scoped lang="scss">
.walletSet {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .top {
        background-color: #1A2C38;
        height: 60px;
        display: flex;
        justify-content: space-between;

        .logo {
            display: flex;
            align-items: center;

            & > img {
                height: 70%;
            }
        }

        .withdraw {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            color: #fff;
            font-weight: bold;
        }
    }

    .txt {
        width: 92%;
        margin: 20px auto;

        .txt1 {
            color: #fff;
            font-weight: bold;
            font-size: 24px;
        }

        .txt2 {
            color: #b1bad3;
            font-size: 14px;
        }
    }
}

.txt-label {
    display: block;
    padding: 6px 12px;
    color: #fff;
    font-weight: bold;
}

.btn {
    width: 100%;
    margin: 40px auto;
    height: 45px;
}

.line {
    width: 96%;
    height: 3px;
    margin: 3px auto;
    border-radius: 3px;
    background-color: #00E700;
}

/deep/ .ivu-input {
    height: 40px;
}

/deep/ .ivu-form-item {
    margin-bottom: 18px;
}

/deep/ .ivu-form-item-content {
    width: 94%;
    margin: auto !important;
}
</style>
