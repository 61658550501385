<template>
  <div class="login_form">

<div class="headBox">
   <img src="../assets/logo/back_1.png" alt="" style="width:20px;height:15px;" @click="goback">
  <span style="color:aliceblue;font-size:20px;margin-left: 20px;">
    {{$t('uc.finance.agentdata')}}
  </span>
</div>


<div style="margin-top:100px">
  <Button style="width:40%; height: 40px; background-color: #e5b932; font-size: medium; border: none;  color: #434240; font-weight: 600;"   @click="popdata">{{$t('common.date')}}</Button>
</div>


<div class="head1">
  <span>{{$t('common.date1')}}</span>
  <span>{{totalregist}}</span>
</div>
<div class="head2">

<div class="head22">
<span>{{$t('common.date3')}}</span>
<span>{{totalbet}}</span>
</div>
<div class="head23">
<span>{{$t('common.date7')}}</span>
<span>{{totalValidBet}}</span>
</div>
<div class="head21">
  <span>{{$t('common.date2')}}</span>
  <span>{{totolwin}}</span>
</div>
</div>

<div class="bodyhead">
  <span>{{$t('common.date9')}}</span>
<span>{{$t('common.date4')}}</span>
<span>{{$t('common.date5')}}</span>
<span>{{$t('common.date8')}}</span>
<span>{{$t('common.date6')}}</span>
</div>

<todo-list style="height:65%;" message='2' :dataList = 'dataList'/>

<calendar :show.sync="calendarShow" :defaultDate="defaultDate" mode="during" @change="onChange"/>

</div>
</template>

<script>
  import TodoList from '@/components/TodoList'
  import dayjs from 'dayjs';
export default {
  components: {
    TodoList
  },
  data(){
    return{
      calendarShow:false,
      dataList:[],
      startDate: '',
      endDate : '',
      defaultDate:[ dayjs(), dayjs().add(-30, 'day') ],
      totalregist:'',
      totolwin:'',
      totalbet:'',
      totalValidBet:'',
      currentPage: 1,
      pageSize: 10
    }
  },
 

  created() {
    this.endDate = this.defaultDate[0].format('YYYY-MM-DD')
  this.startDate = this.defaultDate[1].format('YYYY-MM-DD')
  this.getAgentData();
 
    // this.getMember();
  },

  methods : {
    goback() {
      //获取个人安全信息
     this.$router.replace("/me");
     
    },
    onChange(date){
console.log('sdfsdfs'+ date)
if (date[0] != null && date[1] != null) {
  this.startDate = date[0].format('YYYY-MM-DD')
this.endDate = date[1].format('YYYY-MM-DD')
this.getAgentData()
}

    },
    popdata(){
this.calendarShow = !this.calendarShow
    },
    getAgentData() {
      //获取个人安全信息
      var self = this;
     let params = {}
     params.startDate = this.startDate
     params.endDate = this.endDate
     params.ccy = ''
   
      this.$http
        .post(self.host + this.api.uc.agentData, params)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
             console.log('wrwrwerw')
             self.totalregist = resp.data.totalRegister
             self.totalbet = resp.data.totalBet
              self.totolwin = resp.data.totalWin
              self.totalValidBet = resp.data.totalValidBet
              self.dataList = resp.data.userList
          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },


  },

}
</script>

<style lang="scss" scoped>
.login_form {
  background-color: black;
  height: $full-page;
  overflow: hidden;
  }



.head1{
  color:#898989 ;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: larger;

}

.head2{
  color:#898989 ;
  display: flex;
  justify-content: space-around;
}
.bodyhead{
  color:#898989 ;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.head21{
  color:#898989 ;
display: flex;
flex-direction: column;
}

.head22{
display: flex;
flex-direction: column;
}

.head23{
display: flex;
flex-direction: column;
}

</style>