<template>

  <div class="login_form">

    <div class="headBox">
      <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
      <span  style="font-size:20px;">
        {{$t('uc.finance.addaddress')}}
      </span>
      <span>{{  }}</span>
      <!-- <span v-if="model1 == 'USDT'" @click="changeType">{{ $t('uc.finance.cards') }}</span>
      <span v-else @click="changeType">{{ 'USDT' }}</span> -->
    </div>

      <div class="rightarea">


      <section style="margin-top:60px;">
        <div class="butt0">
     <span>TRC20</span>
  </div>
     <div class="celllall">
      <!-- <span style="margin-left:0px">
        {{$t('uc.forget.walletaddress')}}
      </span> -->
      <Input v-model="inputAddress" size="large" style="color: #898989;border-radius: 10px; border: none; border-inline-width: 1px; width:90%;" :disabled='isdisable' clearable   :placeholder="$t('uc.forget.addresstip')" />

    </div>

      <!-- <div style="margin-top:20px" >
        <span style="font-size: 14px;color: #a2a2a1;">{{$t('uc.finance.personalassets')}}</span>
      </div>
      <div>
        <Button  style="background-color:#e5b932;border-radius: 25px; height: 40px; width: 100px; font-weight: bold; border: none;margin: 10px;margin-top: 0%;">TRC20</Button>
      </div> -->

      <div style="margin-top:80px;">
        <Button  style="width: 90%;margin-top: 10px;background-color: #f4850d;border: none; color: aliceblue;" :disabled='isdisable'  @click="firstModel">{{$t('uc.forget.save')}}</Button>
      </div>


      </section>

      <!-- <div style="margin-top: 20px;">
          <span style="font-size: 12px;color:#f60;">{{$t('uc.finance.notice12')}}</span>

        </div> -->

      </div>

      <Modal
        v-model="modal2"
        :title="$t('common.tip')"
        @on-ok="submitSecond"
        @on-cancel="cancel">
        <p>{{$t('uc.finance.notice12')}}</p>

    </Modal>

     </div>

</template>

<script>

export default {
  name: 'UsdtMaker',
  components: {

  },
  data(){
    return{
            model1:'USDT',
            model11:'',
            model111: '',
            bankList: [],
            bankCards:[],
            usdtCards:[],
            modal2: false,
 value2: '',
 value3: '',
 isdisable:false,
 inputAddress: "", //用户输入的地址
 cityList: [
                    {
                        value: 'VND',
                        label: 'VIENAM'
                    },
                    {
                        value: 'USD',
                        label: 'CAMBODIA'
                    },
                    {
                        value: 'CNY',
                        label: 'CHINA'
                    },]
    }
  },
  computed:{


    closure () {
   return function (data) {
        /** do something */
        return data.bankName == 'KHQR' || data.bankName == 'QR Code'

    }
 }
  },
created() {

    // this.getList();

    let xx =  this.$route.params.bankCards
    if (xx == '0') {
      console.log('');
    }else if (xx == '1') {
      this.model1 = 'bankCards'
    }
    this.getBankCards();
    this.getMemberInfo();
    // this.getAddress();
  },
  methods: {
    cancel(){
      this.modal2 = false
    },

    submitFirst(){
      this.modal2 = true
    },

    submitSecond(){
      this.addBankCard()
    },

    changeType(){
if (this.model1 == 'USDT') {
  this.model1 = 'bankCards'
}else if (this.model1 == 'bankCards') {
  this.model1 = 'USDT'
}

    },
    changeCountry(data){
      if (data == 'VND') {
        console.log();

      }else if (data == 'USD-cam') {
        data = 'USD'
      }else if (data == 'CNY') {
        console.log();
      }

      this.getBankList(data)
    },

    gotoUSDT(){
      this.$router.push('/usdtMaker')
    },

    getBankList(data){
      let that = this
      let param = {
        'transCurrency':data,
        'language':this.$i18n.locale
      }

      this.$http.get(this.host + this.api.uc.cambodiaWithdrawBanks, {params: param}).then(response => {
          var resp = response.body;
          this.loading = false;

          if (resp.code == "SUCCESS") {
            that.bankList = resp.banks

          } else {
            that.$Message.error(resp.message);
          }
        });
    },
    getMemberInfo() {
      //获取个人安全信息
      var self = this;

      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
         let xx = resp.data

          // if (xx.bindStatus == '0') {
          //   self.isdisable = false
          // }else{
          //   self.inputAddress = xx.bindAddress
          // }

          // self.value2 = xx.realName

console.log(''+xx);


          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },


firstModel(){
  //  this.modal10 = true;
  this.addAddress();
},


addBankCard(){
  let self = this
  let param = {}

  if (this.value3 == '' || this.model111 == '' || this.value2 == '') {
    this.$Message.error(this.$t('uc.finance.xx100'));
    return
  }

param.walletAddress = this.value3
param.bankName = this.model111
param.walletName = this.value2
param.addressChannel = 0
param.ccy = this.model11
param.userNo = this.$store.getters.member.userNo
param.language = this.$i18n.locale || 'en-US'

this.$http
        .post(self.host + this.api.uc.identification, param)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {

            self.$Message.success(resp.mesg);
            self.getBankCards()
            self.clearVars()
            self.modal2 = false
            // self.isdisable = true
          } else {
            self.$Message.error(resp.mesg);
            self.modal2 = false
            // this.$Message.error(this.$t('common.logintip'));
          }
        });

},
clearVars(){
  this.model11 = ''
  this.value2 = ''
  this.model111 = ''
  this.value3 = ''


},

getBankCards(){
  let self = this
  this.$http
        .post(self.host + this.api.uc.wallet)
        .then(response => {
          let resp = response.body
          if (response.status == 200) {
            console.log('' + resp.data);
           self.bankCards = []
           self.usdtCards = []
              resp.forEach(element => {
                if (element.ccy == 'USDT') {
                  self.usdtCards.push(element)
                }else {
                  self.bankCards.push(element)
                }
              });

          }

        })
},
    addAddress() {
  //  /user/addWithdrawalAddress    入参：Integer addressChannel（0：区块链，1：汇旺，2：银行卡），
  //   String walletName(账号姓名)（选择区块链隐藏此选项），String walletAddress(提现账号)，String ccy（币种）
      var self = this;
     var params = {
          };

          params['addressChannel'] = 0
          params['walletAddress'] = this.inputAddress
          params['ccy'] = 'USDT'
          params['ccySymbol'] = 'TRC20'

      this.$http
        .post(self.host + this.api.uc.identification, params)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {

            self.$Message.success(resp.mesg);
            self.getBankCards()
            // self.isdisable = true
          } else {
            self.$Message.error(resp.mesg);
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },

goback() {
      //获取个人安全信息
      this.$router.back(-1)

    },
  }
}
</script>

<style lang="scss" scoped>

.login_form{
    background-color: $color-background;
    height: $full-page;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  z-index: 200;
   width: 100%;

}

.butt0{
 background-image: url(../assets/logo/xx22.png);
 background-position: center;
 background-size: 100% 100%;
 height: 40px;
 width: 180px;
 background-repeat: no-repeat;
 color: #fff;
 padding-top: 10px;
 margin-top: 20px;
 margin-left: 20px;

}

  .celllall{
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #a2a2a1;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 20px;
}



.bankcard{
  display:flex;
  flex-direction:column;
  color: #738191;
  text-align:left;
  background:#ffffff;
  padding: 20px;
  margin: 5px;
  width: 100%;
  font-size:small;
  border-radius: 10px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

/deep/ .ivu-input{
  border-radius: 8px;
  border: 1px solid #2f2f2f;
  background-color: #1B2C38;
  color: #fff;
}


  //  .rightarea {
  // width: 100%;
  // height: 400px;
  // background: url(../assets/netmage/hhss.png);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: 95% 100%;
  // margin-top: 100px;
  // padding: 20px;

  //   }

//     .rightarea /deep/ .ivu-select-selection {

//     background-color: #e5b932;
//     border-radius: 15px;
//     color: #000;
//     font-weight: bolder;
// }

// .bill_box{
//   margin-top: 10%;
// }

// .bill_box /deep/ .ivu-select-selection {

//     background-color: #e5b932;
//     border-radius: 15px;
//     color: #000;
//     font-weight: bolder;
// }

</style>


<!-- <style scoped lang="less">

// class="ivu-input ivu-input-default"
  /deep/ .ivu-input{
  background: transparent;
  border-radius: 25px;

  color: #898989
}



</style> -->

