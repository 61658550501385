<template>
     <div class="nav-rights">
        <div class="box222">
            <HeaderNav></HeaderNav>
        </div>
  

        <!-- {
    "name": "Roulette 10",
    "icon": "Live yu le chang",
    "amount":3680000,
    "currency": "idr"
}, -->


<div style="display: flex;justify-content: space-between;height: 50px;padding: 1em 2em 2em; color: #b1bad3; font-weight: bold;background-color: #0f212e;width: 94%;margin-left: 3%;">
    <span>{{ $t('common.agSport') }}</span>
    <span>{{ $t('common.sbSport') }}</span>
</div>

  <div class="sports">

  
  
         
              <div dis-hover v-for="(item, index) in datalist" :key="index" :class="index%2 == 0? 'active': 'notactive'">
  
  <div style="width: 100%;display: flex;flex-direction: row;justify-content: space-between;padding-left: 5px;padding-right: 5px;justify-items: center;">
    <div style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 50%; display: flex;justify-items: center;" align="left" >
        <img v-if="item.icon == 'Mago tui jian'" width="13" src="../assets/logo/hot.svg" alt="">
    <img v-else-if="item.icon == 'Dian Zi'" width="15"   src="../assets/logo/slots.svg" alt="">

    <img v-else-if="item.icon == 'Xin you xi'" width="13" src="../assets/logo/newRelease.svg" alt="">

    <img v-else-if="item.icon == 'You xi jie mu'" width="13" src="../assets/logo/gameshow.svg" alt="">

    <img v-else src="../assets/logo/live.svg"  width="14"   alt="">

<span style="color: aliceblue;font-size:13px;margin-left: 5px; font-family: rabikiso; font-weight: lighter;">{{ item.name }}</span>
    </div>
  
<div style="display: flex;justify-items: center;">
    <span v-if="Number( item.amount ) > 0" style="color: #1fff20;font-size:14px;margin-right: 5px; font-family:SmileySans-Oblique;letter-spacing: 1px;">{{ item.amount }}</span>

    <span v-else style="color: #b1bad3;font-size:14px;margin-right: 5px;">{{ item.amount }}</span>

    
    <img v-if="item.currency == 'usdt'" width="12" height="12" style="margin-top: 4px;" src="../assets/logo/usdt.png" alt="">
    <span v-else style="color: #b1bad3;font-size:14px">{{ item.currency }}</span>
</div>

</div>
  
  
  </div>
  
     
  </div>
  
  
  

  <Main-tab-bar></Main-tab-bar>
  
    </div>
  </template>
  
  <script>
  
import bet from '../../public/bet.json'
  import MainTabBar from '../components/tabbar/MainTabBar'

import HeaderNav from "@/components/HeaderNav.vue";
  
  
  export default {
    components: {
        MainTabBar,
        HeaderNav,
    },
   data() {
  return{
  datalist:[],
  datalist1: [],
  startDate: '',
        endDate : '',
        totalregist:'',
        totolwin: 0,
        totalbet: 0,
        totalValidBet: 0,
        calendarShow:false,
        currentPage: 1,
        totalPage: 0,
        isActive: 0,
        iSportTouched: false,
        isGameTouched: false,
        iSportSubActive: 0,
        isGameSubActive: 2,
        realHeight: 0,
        isReachBottom: false,
        sportTab:'sp1',
        gameTab:'g1',
        isSport:  false,
        windowHeight: 0,
        currentName: '',
        value8:false,
        rechargestatus: '',
        withdrawstatus: '',
        commonstatus: '',
        currentab: '',
        showxxy: ''
  }
   },
  
   mounted(){
  
    this.windowHeight = document.documentElement.clientHeight;
    console.log('yyyyyy '+this.windowHeight);
  
    this.realHeight = Number(this.windowHeight) - 100
   
    },
  
  created() {
    let tempdata = bet
    let index = 0
    for (index = 0; index < 12; index++) {
      let xx = tempdata[index]
      xx.name = this.limitWords(xx.name)
        this.datalist.push(xx)
        console.log('sdfsdf'+xx);

    }


    this.timercode = setInterval(() => {
       index++
       index = index%tempdata.length
       let xx = tempdata[index]
       xx.name = this.limitWords(xx.name)
       this.datalist.unshift(xx)
       this.datalist.pop()
      }, 1000);
    },

  
    beforeDestroy() {
      // inobounce.disable()
    //   document.getElementById('outbody').style.overflow = ''
    clearInterval(this.timercode)
    },
  
  methods: {
  

    limitWords(txt){
    var str = txt;
    if (str.length > 20) {
      str = str.substr(0,20) + '...';

    }
    return str;
},
  
  billType(status){
  let xyz = ''
  switch (status) {
          case 100:
            xyz = this.$t('uc.finance.xx71')
            break;
            case 101:
            xyz = this.$t('uc.finance.xx70')
            break;
            case 102:
            xyz = this.$t('uc.finance.xx72')
            break;
          default:
            break;
        }
  return xyz
      },
    
  },
  
  
  
  
  
  }
  </script>
  
  <style lang="scss" scoped>
  .ellipsis {
  width: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
  
  .nav-rights {
    background: $color-background;
    height: $full-page;
    position: relative;
    // overflow: hidden;
    text-align: center;

    .box222 {
        height: 60px;
        width: 100%;
    }
}
  .box{
    position: fixed;
    top: 0;
    display: flex;
    height: 50px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    color: $color-text-tag;
    z-index: 200;
     width: 100%;
     background-color: $color-background;
  }



  .head_box{
        display: flex;
        align-items: center;
        width: 100%;
        color: #fff;
        font-size: small;
        height: auto;
        // padding-top: 10px;
      }
  
  
      .body_box{
         display: flex;
        color: #fff;
        font-size: 15px;
        width: 100%;
  justify-content: space-between;
  align-items: center;
        // margin-left: 5%;
        // margin-top: 20px;
        height: auto;
  
      }
  
      .body_box1{
         display: flex;
        color: #fff;
        font-size: 15px;
        width: 100%;
  align-items: center;
  
        height: auto;
  
      }
  
      /deep/ .ivu-card-body{
    padding:  10px 10px;
  
  }
  
  .head2{
    color:#898989 ;
    display: flex;
    justify-content: space-around;
    margin-top:60px;
    width: 100%;
  
  }
  .bodyhead{
    color:#898989 ;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  /deep/ .ivu-card-bordered{
    border: none;
  }
  
  
  
  /deep/ .ivu-btn{
    border: none
  }
  
  .head22{
  display: flex;
  flex-direction: column;
  font-size: medium;
  }
  
 
  .gamerecords{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: baseline;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
   /deep/ .ivu-tabs-bar{
    border-bottom: 0px solid #dcdee2;
    margin-bottom: 10px;
    background-color: #1B2C38;
  }
  
  .active{
    height: 55px;
    padding: 15px 10px;
    width: 90%;
    border-radius: 5px;
    background-color:#213743;
    margin-left: 4%;
  }
  
  .notactive{
    height: 55px;
    padding: 15px 10px;
    width: 90%;
    margin-left: 4%;

  }
  
  /deep/ .ivu-tabs-nav-container{
    font-size: 12px;
  }
  
  /deep/ .ivu-tabs-nav  .ivu-tabs-tab-active{
    color: #d2b965;
  }
  
  
  /deep/ .ivu-tabs-tab{
    color: #fff;
    padding: 12px 16px
  }
  
  
  
  /deep/ .ivu-tabs-ink-bar{
    background-color: #d2b965;
  }
  
  .xxx1{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 34%;
  }
  
  .xxx2{
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1px;
  width: 45%;
  }
  .xxx3{
    display: flex;
  align-items: flex-start;
  width: 20%;
  }
  </style>
  
  
  
  