<template>
    <div class="login_form">

<div class="headBox">
  <img src="../assets/logo/back.png" alt="" style="width:20px;height:20px;" @click="goback">
  <span  style="font-size:20px;">
    {{$t('uc.finance.addaddress')}}
  </span>
  <!-- <span v-if="model1 == 'USDT'" @click="changeType">{{ $t('uc.finance.cards') }}</span>
  <span v-else @click="changeType">{{ 'USDT' }}</span> -->
  <span>{{  }}</span>
</div>

  <div class="rightarea">
 

  <!-- <section style="margin-top:60px;" v-if="model1 == 'USDT'">

 <div class="celllall">
  <span style="margin-left:0px">
    {{$t('uc.forget.walletaddress')}}
  </span>
  <Input v-model="inputAddress" style="width:90%;" :disabled='isdisable' clearable   :placeholder="$t('uc.forget.addresstip')" />

</div>
 
  <div style="margin-top:20px" >
    <span style="font-size: 14px;color: #a2a2a1;">{{$t('uc.finance.personalassets')}}</span>
  </div>
  <div>
    <Button  style="background-color:#e5b932;border-radius: 25px; height: 40px; width: 100px; font-weight: bold; border: none;margin: 10px;margin-top: 0%;">TRC20</Button>
  </div>
  <div style="margin-top:20px;">
    <Button type="success" style="width: 90%;" :disabled='isdisable'  @click="firstModel">{{$t('uc.forget.save')}}</Button>
  </div>
     

  <div style="width: 100%;padding: 10px; ">
  <Row>
    <Col span="12" v-for="(item, index) in usdtCards"
    :key="index"
    :index="index"
    >
        <div class="bankcard">

        <span>{{ $t('uc.finance.xx122') }} : {{ item.walletAddress }}</span>
        
        </div>
    </Col>
  </Row>
    
    
    </div>
  </section> -->



    <section style="margin-top:60px;">
      <Select v-model="model11" style="width:90%" @on-change="changeCountry">
    <Option v-for="item in cityList" :value="item.value" :key="item.value" >
     
      {{ item.label }}
    </Option>
</Select>

<Input v-model="value2" style="width:90%;margin-top: 10px;"  :placeholder="$t('common.realNameInput')" />

<Select v-model="model111" style="width:90%; margin-top: 10px;" :label-in-value="true" @on-change="selectItem1">
  <img :src="curretnpage" alt="" width="20" height="20" slot="prefix" style="margin-right: 10px;">
    <Option v-for="item in bankList" :value="item.bankCode" :key="item.value" :disabled="closure(item)" style="display: flex;align-items: center;">
      <img :src="item.img" width="20" height="20" alt="" style="margin-right: 10px;">
      {{ item.bankName }}
    </Option>
</Select>

<Input v-model="value3" style="width:90%; margin-top: 10px;" :placeholder="$t('uc.forget.addresstip222')" />
<Button type="success" style="width: 90%;margin-top: 10px;background-color: #f4850d;border: none;" @click="submitFirst" >{{ $t('uc.forget.save') }}</Button>



  </section>


  <!-- <div style="margin-top: 20px;">
      <span style="font-size: 12px;color:#f60;">{{$t('uc.finance.notice12')}}</span>
      
    </div> -->

  </div>

  <Modal
    v-model="modal2"
    :title="$t('common.tip')"
    @on-ok="submitSecond"
    @on-cancel="cancel">
    <p>{{$t('uc.finance.notice12')}}</p>
   
</Modal>
 
 </div>
</template>

<script>

export default {
  name: 'CardMaker',
  components: {

  },
  data(){
    return{
            model1:'USDT',
            model11:'',
            model111: '',
            bankList: [],
            bankCards:[],
            usdtCards:[],
            modal2: false,
 value2: '',
 value3: '',
 curretnpage: '',
 isdisable:false,
 inputAddress: "", //用户输入的地址
 cityList: [
                    {
                        value: 'VND',
                        label: 'VIENAM'
                    },
                    {
                        value: 'USD',
                        label: 'CAMBODIA'
                    },
                    {
                        value: 'CNY',
                        label: 'CHINA'
                    },]
    }
  },
  computed:{
    

    closure () {
   return function (data) {
        /** do something */
        return data.bankName == 'KHQR' || data.bankName == 'QR Code'

    }
 }
  },
created() {
    
    // this.getList();

    let xx =  this.$route.params.bankCards
    if (xx == '0') {
      console.log('');
    }else if (xx == '1') {
      this.model1 = 'bankCards'
    }
    this.getBankCards();
    this.getMemberInfo();
    // this.getAddress();
  },
  methods: {
    cancel(){
      this.modal2 = false
    },
    
    submitFirst(){
      this.modal2 = true
    },

    submitSecond(){
      this.addBankCard()
    },

    selectItem1(item){
      

      // if (item.label.includes('KHQR')) {
      //   this.curretnpage = require('@/assets/logo/KHQR_1.png')
      // }else if (item.label.includes('eMoney')) {
      //   this.curretnpage = require('@/assets/logo/zz6.png')
      // }else if (item.label.includes('Wing Bank')) {
      //   this.curretnpage = require('@/assets/logo/wing11.png')
      // }else if (item.label.includes('ABA Bank')) {
      //   this.curretnpage = require('@/assets/logo/zz7.png')
      // }else if (item.label.includes('ACLEDA Bank')) {
      //   this.curretnpage = require('@/assets/logo/zz2.png')
      // }else if (item.label.includes('VIETCOMBANK')) {
      //   this.curretnpage = require('@/assets/logo/zz1.png')
      // }else if (item.label.includes('MBBANK')) {
      //   this.curretnpage = require('@/assets/logo/zz4.png')
      // }else if (item.label.includes('TECHCOMBANK')) {
      //   this.curretnpage = require('@/assets/logo/zz3.png')
      // }
      // switch (item.label) {
      //           case "KHQR":
      //            this.curretnpage = require('@/assets/logo/KHQR_1.png')
                 
      //             break;
      //           case "eMoney":
      //           this.curretnpage = require('@/assets/logo/zz6.png')
                 
      //             break;
      //           case "Wing Bank":
      //           this.curretnpage = require('@/assets/logo/wing11.png')
                 
      //             break;
      //           case "ABA Bank":
      //           this.curretnpage = require('@/assets/logo/zz7.png')
                
      //             break;
      //           case "ACLEDA Bank":
      //           this.curretnpage = require('@/assets/logo/zz2.png')
                 
      //             break;  
      //           case "VIETCOMBANK":
      //           this.curretnpage = require('@/assets/logo/zz1.png')
      //             break;
      //           case "MBBANK":
      //           this.curretnpage = require('@/assets/logo/zz4.png')

      //                       break;
      //           case "TECHCOMBANK":
      //           this.curretnpage = require('@/assets/logo/zz3.png')
      //             break;   

                
      //           default:
      //             break;
      //         }

     },

    changeType(){
if (this.model1 == 'USDT') {
  this.model1 = 'bankCards'
}else if (this.model1 == 'bankCards') {
  this.model1 = 'USDT'
}

    },
    changeCountry(data){
      if (!data) {
        return
      }
      if (data == 'VND') {
        console.log();
        
      }else if (data == 'USD-cam') {
        data = 'USD'
      }else if (data == 'CNY') {
        console.log();
      }

      this.curretnpage = ''


      this.getBankList(data)
    },

    gotoBank(){
      this.$router.push('/cardMaker')
    },

    getBankList(data){
      let that = this
      let param = {
        'transCurrency':data,
        'language':this.$i18n.locale
      }

      this.$http.get(this.host + this.api.uc.cambodiaWithdrawBanks, {params: param}).then(response => {
          var resp = response.body;
          this.loading = false;

          if (resp.code == "SUCCESS") {
            that.bankList = resp.banks
            that.bankList.forEach(element => {
              // if (element.bankName == 'eMoney') {
              //       element.img = require('@/assets/logo/zz6.png')
              //     }
              //     else if (element.bankName == 'Wing Bank') {
              //       element.img = require('@/assets/logo/wing11.png')
              //     }
              //     else if (element.bankName == 'ABA Bank') {
              //       element.img = require('@/assets/logo/zz7.png')
              //     }
              //     else if (element.bankName == 'ACLEDA Bank') {
              //       element.img = require('@/assets/logo/zz2.png')

              //     }else if (element.bankName == 'VIETCOMBANK') {
              //       element.img = require('@/assets/logo/zz1.png')
              //     }
              //     else if (element.bankName == 'MBBANK') {
              //       element.img = require('@/assets/logo/zz4.png')
              //     } else if (element.bankName == 'TECHCOMBANK') {
              //       element.img = require('@/assets/logo/zz3.png')
              //     }
            });

          } else {
            that.$Message.error(resp.message);
          }
        });
    },
    getMemberInfo() {
      //获取个人安全信息
      var self = this;
     
      this.$http
        .post(self.host + this.api.uc.memberInfo)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
         let xx = resp.data

          // if (xx.bindStatus == '0') {
          //   self.isdisable = false
          // }else{
          //   self.inputAddress = xx.bindAddress
          // }

          // self.value2 = xx.realName

console.log(''+xx);

          
          } else {
            self.$Message.error(resp.mesg);
          }
        });
    },


firstModel(){
  //  this.modal10 = true;
  this.addAddress();
},


addBankCard(){
  let self = this
  let param = {}

  if (this.value3 == '' || this.model111 == '' || this.value2 == '') {
    this.$Message.error(this.$t('uc.finance.xx100'));
    return
  }

param.walletAddress = this.value3
param.bankName = this.model111
param.walletName = this.value2
param.addressChannel = 2
param.ccy = this.model11
param.userNo = this.$store.getters.member.userNo

this.$http
        .post(self.host + this.api.uc.identification, param)
        .then(response => {
          var resp = response.body;
          self.modal2 = false
          if (resp.code == "0000") {
           
            self.$Message.success(resp.mesg);
            self.getBankCards()
            self.clearVars()
            
            // self.isdisable = true
          } else {
            self.$Message.error(resp.mesg);
            // this.$Message.error(this.$t('common.logintip'));
          }
        });

},
clearVars(){
  this.model11 = ''
  this.value2 = ''
  this.model111 = ''
  this.value3 = ''


},

getBankCards(){
  let self = this
  this.$http
        .post(self.host + this.api.uc.wallet)
        .then(response => {
          let resp = response.body
          if (response.status == 200) {
            console.log('' + resp.data);
           self.bankCards = []
           self.usdtCards = []
              resp.forEach(element => {
                if (element.ccy == 'USDT') {
                  self.usdtCards.push(element)
                }else {
                 
                  self.bankCards.push(element)
                }
              });
           
          }else{
            self.$Message.error(resp.mesg);
          }

        })
},
    addAddress() {
  //  /user/addWithdrawalAddress    入参：Integer addressChannel（0：区块链，1：汇旺，2：银行卡），
  //   String walletName(账号姓名)（选择区块链隐藏此选项），String walletAddress(提现账号)，String ccy（币种）
      var self = this;
     var params = {
          };
         
          params['addressChannel'] = 0
          params['walletAddress'] = this.inputAddress
          params['ccy'] = 'USDT'
          params['ccySymbol'] = 'TRC20'
         
      this.$http
        .post(self.host + this.api.uc.identification, params)
        .then(response => {
          var resp = response.body;
          if (resp.code == "0000") {
           
            self.$Message.success(resp.mesg);
            self.getBankCards()
            // self.isdisable = true
          } else {
            self.$Message.error(resp.mesg);
            // this.$Message.error(this.$t('common.logintip'));
          }
        });
    },

goback() {
      //获取个人安全信息
      this.$router.back(-1)
     
    },
  }
}
</script>

<style lang="scss" scoped>

.login_form{
    background-color: $color-background;
    height: $full-page;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.box{
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  color: $color-text-tag;
  z-index: 200;
   width: 100%;
}

  .celllall{
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #a2a2a1;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 20px;
}


.bankcard{
  display:flex;
  flex-direction:column;
  color: #738191;
  text-align:left;
  background:#ffffff;
  padding: 5px;
  margin: 5px;
  font-size:small;
  border-radius: 10px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    word-break: break-all;
}



</style>

