/*赛事阶段 */
export const sportsStatus = {
    '1000': {
        'en': 'SoccerRealTime', 'cn': '足球实时'
    }, '1001': {
        'en': 'SoccerFullTime', 'cn': '足球全场'
    }, '1002': {
        'en': 'SoccerFistHalf', 'cn': '足球上半场'
    }, '1003': {
        'en': 'SoccerSecondHalf', 'cn': '足球下半场'
    }, '1004': {
        'en': 'SoccerExtraFirstHalf', 'cn': '足球加时上半场'
    }, '1005': {
        'en': 'SoccerExtraSecondHalf', 'cn': '足球加时下半场'
    }, '1006': {
        'en': 'SoccerPenaltyKick', 'cn': '足球点球罚球'
    }, '1007': {
        'en': 'Soccer 0-15Min', 'cn': '足球0-15min'
    }, '1008': {
        'en': 'Soccer 15-30Min', 'cn': '足球15-30min'
    }, '1009': {
        'en': 'Soccer 30-45Min', 'cn': '足球30-45min'
    }, '1010': {
        'en': 'Soccer 45-60Min', 'cn': '足球45-60min'
    }, '1011': {
        'en': 'Soccer 60-75Min', 'cn': '足球60-75min'
    }, '1012': {
        'en': 'Soccer 75-90Min', 'cn': '足球75-90min'
    }, '1013': {
        'en': 'SoccerExtraFullTime', 'cn': '足球加时全场'
    }, '1014': {
        'en': 'Soccer Full Time(Incl ET)', 'cn': '足球全场包含加时'
    }, '1015': {
        'en': 'Soccer PenaltyKick(first 5 rounds)', 'cn': '足球点球大战前5回合'
    }, '3001': {
        'en': 'BasketballFullTime', 'cn': '篮球全场（包含加时）'
    }, '3002': {
        'en': 'BasketBallRegularTime', 'cn': '篮球常规时间'
    }, '3003': {
        'en': 'BasketballFirstHalf', 'cn': '篮球上半场'
    }, '3004': {
        'en': 'BasketballSecondHalf', 'cn': '篮球下半场'
    }, '3005': {
        'en': 'BasketballFirstQuarter', 'cn': '篮球第一节'
    }, '3006': {
        'en': 'BasketballSecondQuarter', 'cn': '篮球第二节'
    }, '100999': {
        en: 'Olympic Outright Period', cn: '奥林匹克冠军阶段',
    }, '92999': {
        en: 'Formula 1 Outright Period', cn: 'F1赛车冠军阶段',
    }, '10999': {
        en: 'Floorball Outright Period', cn: '地板球冠军阶段',
    }, '14999': {
        en: 'Cricket Outright Period', cn: '板球冠军阶段',
    }, '18999': {
        en: 'MMA Outright Period', cn: '格斗冠军阶段',
    }, '25999': {
        en: 'Cycling Outright Period', cn: '自行车冠军阶段',
    }, '51999': {
        en: 'Beach Volleyball Outright Period', cn: '沙滩排球冠军阶段',
    }, '94999': {
        en: 'Stock Car Racing Outright Period', cn: '赛车冠军阶段',
    }, '12999': {
        en: 'Golf Outright Period', cn: '高尔夫冠军阶段',
    }, '17999': {
        en: 'Futsal Outright Period', cn: '五人制足球冠军阶段',
    }, '19999': {
        en: 'Boxing Outright Period', cn: '拳击冠军阶段',
    }, '20999': {
        en: 'Dart Outright Period', cn: '飞镖冠军阶段',
    }, '21999': {
        en: 'Bowls Outright Period', cn: '草地滚球冠军阶段',
    }, '24999': {
        en: 'Water Polo Outright Period', cn: '水球冠军阶段',
    }, '4999': {
        en: 'Rugby Outright Period', cn: '橄榄球冠军阶段',
    }, '95999': {
        en: 'Motorcycle Racing Outright Period', cn: '摩托车赛冠军阶段',
    }, '101999': {
        en: 'Asian Game', cn: '亚运会冠军阶段',
    }, '1001001': {
        en: 'Virtual Soccer Full Time', cn: '虚拟足球全场',
    }, '1001002': {
        en: 'Virtual Soccer First Half', cn: '虚拟足球上半场',
    }, '1001003': {
        en: 'Virtual Soccer Extra First Half', cn: '虚拟足球加时上半场',
    }, '1001004': {
        en: 'Virtual Soccer Extra Full Time', cn: '虚拟足球加时全场',
    }, '1001005': {
        en: 'Virtual Soccer Penalty', cn: '虚拟足球点球大战',
    }, '1020001': {
        en: 'Virtual Horse Default Period', cn: '虚拟赛马默认阶段',
    }, '1021001': {
        en: 'Virtual Greyhounds Default Period', cn: '虚拟赛狗默认阶段',
    }, '1022001': {
        en: 'Virtual Speedway Default Period', cn: '虚拟沙地摩托车默认阶段',
    }, '1023001': {
        en: 'Virtual Motorbike Default Period', cn: '虚拟摩托车默认阶段',
    }
}

export const CURRENCY = [
    {en: 'CNY', id: 1, name: '人民币'},
    {en: 'USD', id: 2, name: '美元'},
    {en: 'EUR', id: 3, name: '欧元'},
    {en: 'GBP', id: 4, name: '英镑'},
    {en: 'HKD', id: 5, name: '港币'},
    {en: 'TWD', id: 6, name: '新台币'},
    {en: 'MYR', id: 7, name: '马来西亚吉令'},
    {en: 'SGD', id: 8, name: '新加坡元'},
    {en: 'THB', id: 9, name: '泰铢'},
    {en: 'VND', id: 10, name: '越南盾'},
    {en: 'KRW', id: 11, name: '韩元'},
    {en: 'JPY', id: 12, name: '日元'},
    {en: 'PHP', id: 13, name: '菲律宾披索'},
    {en: 'IDR', id: 14, name: '印尼盾'},
    {en: 'INR', id: 15, name: '印度卢比'},
    {en: 'AUD', id: 16, name: '澳大利亚元'},
    {en: 'MMK', id: 17, name: '缅币'},
    {en: 'COP', id: 18, name: '哥伦比亚比索'},
    {en: 'TZS', id: 19, name: '坦桑尼亚先令'},
    {en: 'NGN', id: 20, name: '尼日利亚奈拉'},
    {en: 'ZMW', id: 21, name: '赞比亚克瓦查'},
    {en: 'BRL', id: 22, name: '巴西雷亚尔'},
    {en: 'MXN', id: 23, name: '墨西哥比索'},
    {en: 'RUB', id: 24, name: '俄罗斯卢布'},
    {en: 'EGP', id: 25, name: '埃及镑'},
    {en: 'PEN', id: 26, name: '秘鲁索尔'},
    {en: 'BOB', id: 27, name: '玻利维亚诺'},
    {en: 'PKR', id: 28, name: '巴基斯坦卢比'},
    {en: 'BDT', id: 29, name: '孟加拉塔卡'},
    {en: 'UGX', id: 30, name: '乌干达先令'},
    {en: 'ETB', id: 31, name: '埃塞俄比亚比尔'},
    {en: 'ZAR', id: 32, name: '南非兰特'},
    {en: 'HNL', id: 33, name: '洪都拉斯伦皮拉'},
    {en: 'CDF', id: 34, name: '刚果法郎'},
    {en: 'TRY', id: 35, name: '土耳其里拉'},
    {en: 'IRR', id: 36, name: '伊朗里亚尔'},
    {en: 'GHS', id: 37, name: '加纳赛地'},
    {en: 'KES', id: 38, name: '肯尼亚先令'},
    {en: 'ILS', id: 39, name: '以色列新谢克尔'},
    {en: 'CLP', id: 40, name: '智利比索'},
    {en: 'KZT', id: 41, name: '哈萨克斯坦坚戈'},
    {en: 'KGS', id: 42, name: '吉尔吉斯斯坦索姆'},
    {en: 'TJS', id: 43, name: '塔吉克斯坦索莫尼'},
    {en: 'TMT', id: 44, name: '土库曼斯坦马纳特'},
    {en: 'UZS', id: 45, name: '乌兹别克斯坦苏姆'},
    {en: 'LAK', id: 46, name: '老挝基普'},
    {en: 'BND', id: 47, name: '文莱元'},
    {en: 'CAD', id: 48, name: '加拿大元'},
    {en: 'NZD', id: 49, name: '新西兰元'},
    {en: 'PLN', id: 59, name: '波兰兹罗提'},
    {en: 'ARS', id: 60, name: '阿根廷比索'},
    {en: 'KHR', id: 61, name: '柬埔寨瑞尔'},
    {en: 'NIO', id: 62, name: '尼加拉瓜科多巴'},
    {en: 'ZWL', id: 63, name: '津巴布韦元'},
    {en: 'IMP', id: 64, name: '曼岛镑'},
    {en: 'LKR', id: 65, name: '斯里兰卡卢比'},
    {en: 'USDT', id: 200, name: '泰达币'},
    {en: 'BTC', id: 201, name: '比特币'},
    {en: 'ETH', id: 202, name: '以太坊'},
    {en: 'LTC', id: 203, name: '莱特币'},
    {en: 'DOGE', id: 204, name: '狗狗币'},
    {en: 'BCH', id: 205, name: '比特币现金'},
    {en: 'DASH', id: 206, name: '达世币'},
    {en: 'ETC', id: 207, name: '以太坊经典'},
    {en: 'USDC', id: 209, name: 'USDC'},
    {en: 'XRP', id: 210, name: '瑞波币'},
    {en: 'VNDK', id: 1000, name: '越南盾(K)'},
    {en: 'IDRK', id: 1001, name: '印度尼西亚卢比(K)'},
    {en: 'IRRK', id: 1003, name: '伊朗里亚尔(K)'},
    {en: 'UZSK', id: 1004, name: '乌兹别克斯坦苏姆(K)'},
    {en: 'LAKK', id: 1005, name: '老挝基普(K)'},
    {en: 'KRWK', id: 1007, name: '韩元(K)'},
    {en: 'mBTC', id: 1200, name: '毫比特币'},
    {en: 'mETH', id: 1201, name: '毫以太坊'},
    {en: 'mBCH', id: 1202, name: '毫比特币现金'},
    {en: 'mDASH', id: 1203, name: '毫达世币'},
    {en: 'mETC', id: 1204, name: '毫以太坊经典'},
    {en: 'mLTC', id: 1205, name: '毫莱特币'}
]

