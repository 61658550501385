<template>
  <div class="login_form">
    <!-- <List item-layout="vertical">
        <ListItem v-for="item in datalist" :key="item.id">
            <ListItemMeta  :title="item.tradeNo" :description="item.description" />
            <template slot="extra">
                <img src="../assets/netmage/lisitemback.png" width="90%" height="100%">
            </template>
        </ListItem>
    </List> -->

    <div class="headBox">
      <img src="../assets/logo/back_1.png" alt="" style="width:20px;height:15px;" @click="goback">
      <span style="color:aliceblue;font-size:20px;margin-left: 40px;">
        {{$t('uc.finance.recordetail')}}
      </span>
      <span>{{  }}</span>
    </div>

    <todo-list style="height:100%;" message='1'/>
  </div>
</template>



<script>

import TodoList from '@/components/TodoList'

export default {
  components: {
    TodoList
  },
 data() {
return{
datalist:[]
}
 },


created: function() {
    // this.initGtCaptcha();
  },


methods: {



  goback() {
      //获取个人安全信息
      this.$router.back(-1)
     
    },

 initGtCaptcha() {

       this.$http.post(this.host + this.api.uc.memberactivity).then(response => {
       var resp = response.body;
                    if (resp.code == "0000") {
              this.datalist = resp.data
                    }else
                        this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.message
                      });
      });
    },

}


}
</script>

<style lang="scss" scoped>

// .login_form {
//   background: #0b1520;
//   height: 56rem;
//   position: relative;
//   overflow: hidden;
//   }

  .login_form{
  width: 100%;
  height: $full-page;
  overflow: hidden auto;
  background-color: black;
}

</style>



